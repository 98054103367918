import React, { FC } from 'react';
import { Grid, TextField, Tooltip, styled } from '@mui/material';

import { UtmInputProps } from './utm-input.props';
import question from '../../assets/img/question.svg';
import './utm-input.scss';
import edit from '../../assets/img/edit.svg';
import { inputNullingStyles, inputStyles } from '../../common/input-nulling-styles';

export const UtmInputV2: FC<UtmInputProps> = ({
  name,
  value,
  textFieldHandler,
  canEdit,
  helpInfo,
  canEditHandler,
  placeholder = '',
  required = false,
}) => {
  return (
    <Grid display='flex' maxWidth='420px' justifyContent='center' alignItems='center' container>
      <Grid item xs={12} className='input-title'>
        {name}
        {required ? <span>*</span> : null}
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                background: '#FF990026',
                opacity: '0.1',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#FF9900',
                borderRadius: '6px',
              },
            },
          }}
          title={helpInfo}
        >
          <img src={question} alt='img not found' />
        </Tooltip>
      </Grid>
      <Grid item xs={10}>
        <TextField
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '327px',
            background: '#FFFFFF',
            border: '1px solid #E9E9E9',
            borderRadius: '4px',
            borderShadow: 'none',
            ...inputStyles,
            '&.Mui-focused': {
              borderColor: 'red!important',
            },
            '&::placeholder': {
              fontFamily: 'Inter!important',
              fontStyle: 'normal!important',
              fontWeight: '500!important',
              fontSize: '16px!important',
              lineHeight: '18px!important',
              color: '#B3B3B3',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#FF9900',
              },
            },
          }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => textFieldHandler(e.target.value)}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={2}>
        <div className='edit-img-wrapper-v2' onClick={() => canEditHandler(true)}>
          <img src={edit} alt='edit img' />
        </div>
      </Grid>
    </Grid>
  );
};
