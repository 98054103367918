export const mockdata =  {
  'channels': [{
    'category': 'Channel with ads cabinet',
    'items': [{
      'name': 'Facebook',
      'icon': '/static/img/logo-facebook.4b67e215acd4a65844d6.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Tik-Tok',
      'icon': '/static/img/logo-tiktok.e964ff7703c062af4ed7.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Google',
      'icon': '/static/img/logo-google.3297faffff0a69f2e10f.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Linkedin',
      'icon': '/static/img/logo-linkedin.55007093998729b5ee32.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Twitter',
      'icon': '/static/img/logo-twitter.7e3fe50789940f533d82.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }]
  }, {
    'category': 'SMM',
    'items': [{
      'name': 'Facebook',
      'icon': '/static/img/logo-facebook.4b67e215acd4a65844d6.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Google',
      'icon': '/static/img/logo-google.3297faffff0a69f2e10f.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Linkedin',
      'icon': '/static/img/logo-linkedin.55007093998729b5ee32.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Twitter',
      'icon': '/static/img/logo-twitter.7e3fe50789940f533d82.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Instagram',
      'icon': '/static/img/logo-instagram.65f418f1f8c094cbba24.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }]
  }, {
    'category': 'Other channels',
    'items': [{
      'name': 'PR',
      'icon': '/static/img/logo-pr.d041fbfbff0023f1eb05.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Email',
      'icon': '/static/img/logo-email.f02014e5e2339dfcd9d7.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'CPA',
      'icon': '/static/img/logo-email.f02014e5e2339dfcd9d7.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Crowd',
      'icon': '/static/img/logo-crowd.9ba7f867b161d76f14ce.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'Chat-bots',
      'icon': '/static/img/logo-chat-bots.d2628ee79c61bc7f43c6.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'pdf',
      'icon': '/static/img/logo-pdf.53ac1f3991cc7975cba0.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'events',
      'icon': '/static/img/logo-events.655fd7fe78c7b8758a7c.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }, {
      'name': 'sales manager',
      'icon': '/static/img/logo-sales-manager.b3eab99c011924a1c248.svg',
      'utm_inputs': [{
        'name': 'utm_source',
        'placeholder': 'Google, Facebook, Tik-Tok...',
        'hint': 'Help'
      }, { 'name': 'utm_campaign', 'placeholder': 'campaign name', 'hint': 'Help' }, {
        'name': 'utm_medium',
        'placeholder': 'CPC, email, banner',
        'hint': 'Help'
      }, { 'name': 'utm_content', 'placeholder': 'link, landing page', 'hint': 'Help' }, {
        'name': 'utm_term',
        'placeholder': 'key word',
        'hint': 'Help'
      }]
    }]
  }],
  'parameters': [{
    'category': 'Instagram',
    'icon': null,
    'items': [{
      'name': '{adgroupid}',
      'description': 'Ad group ID. Use this lookup if you\'ve set up tracking information at the account or campaign level and want to know in which ad group your ad was shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }]
  }, {
    'category': 'GoogleAds',
    'icon': null,
    'items': [{
      'name': '{adgroupid}',
      'description': 'Ad group ID. Use this lookup if you\'ve set up tracking information at the account or campaign level and want to know in which ad group your ad was shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }]
  }, {
    'category': 'Tik-Tok',
    'icon': null,
    'items': [{
      'name': '{adgroupid}',
      'description': 'Ad group ID. Use this lookup if you\'ve set up tracking information at the account or campaign level and want to know in which ad group your ad was shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }]
  }, {
    'category': 'Facebook',
    'icon': '/static/img/facebook.173cc4aa1e89f788b1b9.svg',
    'items': [{
      'name': '{adgroupid}',
      'description': 'Ad group ID. Use this lookup if you\'ve set up tracking information at the account or campaign level and want to know in which ad group your ad was shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{adposition}',
      'description': 'Position in search results in the format "1t2" (first page, top, second position).'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }, {
      'name': '{campaignid}',
      'description': 'Campaign ID. Use this if you\'ve set up account-level tracking information and want to know in which campaign your ads were shown.'
    }]
  }],
  'texts': [{
    'name': 'utm_builder',
    'title': 'Utm-links builder',
    'text': 'Create a link that will collect user data and transfer this information to your analytics system or advertising account. Fill in a few fields in the UTM tag generator, and then simply copy the link from UTM to the site.'
  }, {
    'name': 'about',
    'title': 'About service',
    'text': 'In some advertising accounts, you can use substitutions in UTM, which will change the value depending on the settings. For example, the {device} substitution in Google AdWords will show which device the click came from - mobile, computer, or tablet.'
  }, {
    'name': 'about1',
    'title': null,
    'text': 'Translating Cyrillic into Latin. So all analytics systems will accurately recognize the label.'
  }, {
    'name': 'about2',
    'title': null,
    'text': 'Convert all letters to lower case. "Tik-Tok" will become "tik-tok".'
  }, {
    'name': 'about3',
    'title': null,
    'text': 'Replace spaces in words with underscores. "social ad" becomes "social_ad".'
  }, {
    'name': 'about4',
    'title': null,
    'text': 'All characters except "+", "-" and "_" are replaced by "-"'
  }, { 'name': 'tell_about', 'title': 'Enjoy using UTM-builder?', 'text': 'Tell about us in social media' }]
};
