import React from 'react';
import { Grid } from '@mui/material';

import './utm-generator-main-form.scss';
import { Dropdown } from '../dropdown';
import { BaseUrlInput } from '../base-url-input';
import { UtmInput } from '../utm-input';
import { FullUrlInput } from '../full-url-input';
import { hoc } from '../../hoc';
import { IUtmGeneratorHook, useUtmGeneratorHook } from './use-utm-generator.hook';
import IChannel from '../../api/services/content/interfaces/channel.interface';

type InputProps = {
  channels: IChannel[];
};

export const UtmGeneratorMainFormComponent = hoc<InputProps, IUtmGeneratorHook>(
  useUtmGeneratorHook,
  ({
    utmAttributes,
    editAttributes,
    link,
    setSource,
    setMedium,
    setContent,
    setCampaing,
    setTerm,
    setProtocol,
    setBaseUrl,
    canEditSource,
    canEditCampaing,
    canEditMedium,
    canEditContent,
    canEditTerm,
    clearAllAttributes,
    setAllAsEdit,
    channels
  }) => {
    return (
      <Grid container rowSpacing={2}>
        <Grid
          item
          xs={12}
          className="utm-form-dropdown-wrapper utm-form-element-row utm-form-wrapper"
        >
          <Dropdown
            channels={channels || []}
            canEditSource={canEditSource}
            canEditCampaing={canEditCampaing}
            canEditMedium={canEditMedium}
            canEditContent={canEditContent}
            canEditTerm={canEditTerm}
            setSource={setSource}
            setMedium={setMedium}
            setContent={setContent}
            setCampaing={setCampaing}
            setTerm={setTerm}
            setAllAsEdit={setAllAsEdit}
            clearAllAttributes={clearAllAttributes}
          />
        </Grid>
        <Grid item xs={12} className="base-url-grid-wrapper">
          <BaseUrlInput
            selectValue={utmAttributes.utmProtocol}
            selectHandler={setProtocol}
            textInputValue={utmAttributes.utmBaseUrl}
            textInputHandler={setBaseUrl}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container className="utm-form-wrapper" spacing={1}>
            <Grid item md={6} xs={12} className="utm-form-element-row">
              <UtmInput
                textFieldHandler={setSource}
                value={utmAttributes.utmSource}
                name="utm_source"
                canEdit={editAttributes.utmSource}
                canEditHandler={canEditSource}
                helpInfo="Help"
                placeholder="Google, Facebook, Tik-Tok..."
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className="utm-form-element-row">
              <UtmInput
                textFieldHandler={setMedium}
                value={utmAttributes.utmMedium}
                name="utm_medium"
                canEdit={editAttributes.utmMedium}
                canEditHandler={canEditMedium}
                helpInfo="Help"
                placeholder="CPC, email, banner"
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className="utm-form-element-row">
              <UtmInput
                textFieldHandler={setCampaing}
                value={utmAttributes.utmCampaing}
                name="utm_campaing"
                canEdit={editAttributes.utmCampaing}
                canEditHandler={canEditCampaing}
                helpInfo="Help"
                placeholder="campaign name"
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className="utm-form-element-row">
              <UtmInput
                textFieldHandler={setContent}
                value={utmAttributes.utmContent}
                name="utm_content"
                canEdit={editAttributes.utmContent}
                canEditHandler={canEditContent}
                helpInfo="Help"
                placeholder="link, landing page"
              />
            </Grid>
            <Grid item md={6} xs={12} className="utm-form-element-row">
              <UtmInput
                textFieldHandler={setTerm}
                value={utmAttributes.utmTerm}
                name="utm_term"
                canEdit={editAttributes.utmTerm}
                canEditHandler={canEditTerm}
                helpInfo="Help"
                placeholder="key word"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="utm-form-element-row utm-form-wrapper">
          <FullUrlInput value={link}/>
        </Grid>
      </Grid>
    );
  },
);
