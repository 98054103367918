export const inputNullingStyles = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0, boxShadow: 'none' },
  '&:hover': {
    border: '0px solid black',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '0px solid #FF9900',
    borderRadius: '5px 5px 0 0',
  },
};

export const inputStyles = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0, boxShadow: 'none' },
  '&:hover': {
    border: '1px solid #E9E9E9',
    borderRadius: '4px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E9E9E9',
    borderRadius: '4px',
  },
};
