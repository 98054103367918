import { ApiConfig } from './config';

class Api {
  static async get<T>(path: string, params?: { [key: string]: string }): Promise<T> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams(params);
      const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
      fetch(`${ApiConfig.API_URL}${path}${paramsString}`)
        .then((res) => resolve(res.json()))
        .catch(reject);
    });
  }
  static async post<T, U>(path: string, data: T, isAuth?: boolean): Promise<U> {
    return new Promise((resolve, reject) => {
      fetch(`${isAuth ? ApiConfig.LOGIN_URL : ApiConfig.API_URL}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => resolve(res.json()))
        .catch(reject);
    });
  }
}

export default Api;
