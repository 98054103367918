import React from 'react';

import styles from './styles.module.scss';

type InputProps = {
  children: string;
};
const SubmitButton = ({ children }: InputProps) => {
  return (
    <button className={styles.root} type="submit">{children}</button>
  );
};

export default SubmitButton;
