import { FC, useEffect, useState } from 'react';

export interface WindowSizeProps {
  width: number;
  height: number;
}

export const windowSize = <ComponentProps extends object>(
  Source: FC<ComponentProps & WindowSizeProps>,
) => {
  const Component = (props: ComponentProps) => {
    const [windowWidth, setWidth] = useState<number>(window.innerWidth);
    const [windowHeight, setHeight] = useState<number>(window.innerHeight);

    useEffect(() => {
      const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        setWidth(newWidth);
        setHeight(newHeight);
      };

      window.addEventListener('resize', updateWindowDimensions);

      return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);
    return <Source {...props} width={windowWidth} height={windowHeight} />;
  };

  return Component;
};
