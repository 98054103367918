import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import './styles.scss';

export const NotFoundPage = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '50px 0' }}>
      <Typography variant='h3' gutterBottom>
        404
      </Typography>
      <Typography variant='h4' gutterBottom>
        Not Found
      </Typography>
      <Link to='/' className='link'>
        Home
      </Link>
    </Box>
  );
};
