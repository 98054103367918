import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cn from 'classnames';
import { FullUrlInputProps } from './full-url-input.props';
import copy from '../../assets/img/copy.svg';
import './full-url-input.scss';

export const FullUrlInput: FC<FullUrlInputProps> = ({ value, isCabinet }) => {
  return (
    <Box
      className={cn('full-url-wrapper', {
        cabinet: isCabinet,
      })}
    >
      <Box
        className={cn('full-url-input-wrapper', {
          cabinet: isCabinet,
        })}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <p className='full-url-input-text'>Your link</p>
          <input type='text' className='full-input' value={value} />
        </Box>
      </Box>
      <Box className='full-url-img-wrapper'>
        <CopyToClipboard text={value}>
          <img src={copy} alt='img' className='full-url-img' />
        </CopyToClipboard>
      </Box>
    </Box>
  );
};
