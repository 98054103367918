import { UtmInputV2 } from 'components/utm-input/utm-input-v2';
import styles from './utm.module.scss';
import { Grid } from '@mui/material';

export const Utm = ({
  utmAttributes,
  editAttributes,
  setSource,
  setMedium,
  setContent,
  setCampaing,
  setTerm,
  canEditSource,
  canEditCampaing,
  canEditMedium,
  canEditContent,
  canEditTerm,
}: any): JSX.Element => {
  return (
    <div className={styles.utm}>
      <Grid container maxWidth='950px' className='utm-form-wrapper' spacing={1}>
        <Grid item md={6} xs={12} className='utm-form-element-row'>
          <UtmInputV2
            textFieldHandler={setSource}
            value={utmAttributes.utmSource}
            name='utm_source'
            canEdit={editAttributes.utmSource}
            canEditHandler={canEditSource}
            helpInfo='Help'
            placeholder='Google, Facebook, Tik-Tok...'
            required={true}
          />
        </Grid>
        <Grid item md={6} xs={12} className='utm-form-element-row'>
          <UtmInputV2
            textFieldHandler={setMedium}
            value={utmAttributes.utmMedium}
            name='utm_medium'
            canEdit={editAttributes.utmMedium}
            canEditHandler={canEditMedium}
            helpInfo='Help'
            placeholder='CPC, email, banner'
            required={true}
          />
        </Grid>
        <Grid item md={6} xs={12} className='utm-form-element-row'>
          <UtmInputV2
            textFieldHandler={setCampaing}
            value={utmAttributes.utmCampaing}
            name='utm_campaing'
            canEdit={editAttributes.utmCampaing}
            canEditHandler={canEditCampaing}
            helpInfo='Help'
            placeholder='campaign name'
            required={true}
          />
        </Grid>
        <Grid item md={6} xs={12} className='utm-form-element-row'>
          <UtmInputV2
            textFieldHandler={setContent}
            value={utmAttributes.utmContent}
            name='utm_content'
            canEdit={editAttributes.utmContent}
            canEditHandler={canEditContent}
            helpInfo='Help'
            placeholder='link, landing page'
          />
        </Grid>
        <Grid item md={6} xs={12} className='utm-form-element-row'>
          <UtmInputV2
            textFieldHandler={setTerm}
            value={utmAttributes.utmTerm}
            name='utm_term'
            canEdit={editAttributes.utmTerm}
            canEditHandler={canEditTerm}
            helpInfo='Help'
            placeholder='key word'
          />
        </Grid>
      </Grid>
    </div>
  );
};
