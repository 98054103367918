import React from 'react';
import robotIcon from 'assets/img/robot.svg';
import InfoModal from 'components/modals/info-modal';
import styles from './styles.module.scss';
import MainButton from '../../../../components/buttons/main-button';
import clsx from 'clsx';
import { Box } from '@mui/material';

type InputProps = {
  robot?: string;
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  title: string;
  text: string;
};

const ConfirmModal = ({
  robot,
  title,
  text,
  open,
  onClose,
  onNext
}: InputProps) => {
  return (
    <InfoModal
      ellipseTop={true}
      open={open}
      onClose={onClose}
    >
      <div className={styles.root}>
        <div>
          <img src={robot ? robot : robotIcon} alt="" className={styles.robotImg}/>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
          <MainButton onClick={onNext} mobileSensitive={true}>OK</MainButton>
        </div>
        <Box width="100%" textAlign="right">
          <img src={robot ? robot : robotIcon} alt="" className={clsx(styles.robotImg, styles.showMobile)}/>
        </Box>
      </div>
    </InfoModal>
  );
};

export default ConfirmModal;
