import { useState, useRef, useContext } from 'react';
import cx from 'classnames'
import Dropdown from './Dropdown'

import translations from '../../consts/translations'
import dropdown from '../../consts/dropdown'
import styles from './header.module.scss'
import { AppConfig } from '../../config';
import useModalOutsideClick from './useModalOutsideClick';
import { LanguagesEnum } from '../../common/language.type';
import { LanguageContext } from 'layouts/HomeLayout';

const Navigation = () => {
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const productsButtonRef = useRef(null)
  const projectsButtonRef = useRef(null)
  const [openProductDropdown, setOpenProductDropdown] = useState(false)
  const [openProjectDropdown, setOpenProjectDropdown] = useState(false)
  useModalOutsideClick(productsButtonRef, setOpenProductDropdown)
  useModalOutsideClick(projectsButtonRef, setOpenProjectDropdown)


  const handleProductDropdown = () => {
    setOpenProductDropdown(!openProductDropdown)
    setOpenProjectDropdown(false)
  }

  const handleProjectDropdown = () => {
    setOpenProjectDropdown(!openProjectDropdown)
    setOpenProductDropdown(false)
  }

  return (
    <nav className={cx({[styles.header__nav]: true, [styles.nav]: true})}>
      <ul className={styles.nav__list}>
        <li className={styles.nav__item}>
          <a href={language === LanguagesEnum.UK ? 'https://profit.store/uk' : 'https://profit.store'}>
            <span
              className={styles.nav__link}
            >
              {translations[language].header.navigation.home}
            </span>
          </a>
        </li>
        <li className={styles.nav__item}>
          <button
            ref={productsButtonRef}
            className={cx({
              [styles.nav__link]: true,
              [styles['nav__link--selected']]: openProductDropdown
            })}
            onClick={handleProductDropdown}
          >
            {translations[language].header.navigation.products}
            <img
              className={cx({
                [styles.nav__dropdownIcon]: true,
                [styles['nav__dropdownIcon--selected']]: openProductDropdown
              })}
              src={`${AppConfig.IMAGE_URL}/blog/img/arrow_down.svg`}
              alt="Dropdown arrow"
            />
          </button>
          {openProductDropdown && <Dropdown dropdown={dropdown.products} />}
        </li>
        <li className={styles.nav__item}>
          <button
            ref={projectsButtonRef}
            className={cx({
              [styles.nav__link]: true,
              [styles['nav__link--selected']]: openProjectDropdown
            })}
            onClick={handleProjectDropdown}
          >
            {translations[language].header.navigation.projects}
            <img
              className={cx({
                [styles.nav__dropdownIcon]: true,
                [styles['nav__dropdownIcon--selected']]: openProjectDropdown
              })}
              src={`${AppConfig.IMAGE_URL}/blog/img/arrow_down.svg`}
              alt="Dropdown arrow"
            />
          </button>
          {openProjectDropdown && <Dropdown dropdown={dropdown.projects} />}
        </li>
        <li className={styles.nav__item}>
          <a href={language === LanguagesEnum.UK ? 'https://profit.store/blog/uk' : 'https://profit.store/blog'}>
            <span
              className={styles.nav__link}
            >
              {translations[language].header.navigation.blog}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
