import React, { useMemo, useState } from 'react';

import SkipButton from 'components/buttons/skip-button';

import styles from './styles.module.scss';
import {
  ChannelParams,
  emptyValue,
  IChannelParam,
} from '../../../../../components/forms/channel-params';
import { IStepProps } from '../istep.props';
import ConfirmModal from '../../confirm-modal';
import { FieldArray, Form, Formik } from 'formik';
import SubmitButton from '../../../../../components/buttons/main-button/submit';
import { IResponsiblePerson } from '../../../../../modules/project/interfaces/responsible-person';
import { AppConfig } from 'config';
import { BackBtn } from '../../backBtn/backBtn';

export const ChooseResponsible = ({
  onStepFinish,
  onSkip,
  onGoBackStep,
  onGoToNextStep,
  project,
}: IStepProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const initialValues = useMemo(() => {
    if (!project?.channels.length) {
      return {};
    }
    return project?.channels.reduce((hash: any, { id }) => {
      hash[id] = [emptyValue, emptyValue];
      return hash;
    }, {});
  }, [JSON.stringify(project?.channels)]);

  return (
    <div className={styles.root}>
      <div className={styles.root__container}>
        <BackBtn onGoBackStep={onGoBackStep} />
        <div className={styles.root__content}>
          <h2>Step 2/5. Choose responsible persons for each channel</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              const channels = project?.channels.map((channel) => {
                const responsiblePersons = values[channel.id].filter(
                  (v: IChannelParam) => v.agencyName && v.responsibleName,
                );
                return {
                  ...channel,
                  responsiblePersons,
                };
              });
              onStepFinish(channels);
              setOpen(true);
            }}
            render={({ values }) => (
              <Form className={styles.formContent}>
                <div className={styles.items}>
                  {project?.channels.map((channel, index) => (
                    <FieldArray
                      key={channel.id}
                      name={channel.id.toString()}
                      render={(arrayHelpers) => (
                        <ChannelParams
                          field={channel.id.toString()}
                          title={channel.title}
                          icon={channel.icon ? `${AppConfig.UTM_IMAGE_URL}${channel.icon}` : ''}
                          values={values}
                          arrayHelpers={arrayHelpers}
                          defaultHide={false}
                          placeholderName='Add agency name'
                          placeholderValue='Responsible name'
                        />
                      )}
                    />
                  ))}
                </div>
                <div className={styles.actions}>
                  <SkipButton onClick={onSkip} />
                  <SubmitButton>Ready</SubmitButton>
                </div>
              </Form>
            )}
          />

          <ConfirmModal
            open={open}
            onClose={() => setOpen(false)}
            onNext={() => {
              setOpen(false);
              onGoToNextStep();
            }}
            title='Fill in the landing pages and products'
            text='This will help you organize your work better. '
          />
        </div>
      </div>
    </div>
  );
};
