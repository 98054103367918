import React, { useMemo, useState } from 'react';

import SkipButton from 'components/buttons/skip-button';

import styles from './styles.module.scss';
import robotHappyIcon from 'assets/img/robot.svg';
import { IStepProps } from '../istep.props';
import ConfirmModal from '../../confirm-modal';
import { Form, Formik } from 'formik';
import SubmitButton from '../../../../../components/buttons/main-button/submit';
import { ChannelParamsV2 } from '../../../../../components/forms/channel-params-v2';
import { AppConfig } from 'config';
import { BackBtn } from '../../backBtn/backBtn';

type Value = {
  value: string;
};

export const AddAdditionalParameters = ({
  onStepFinish,
  onSkip,
  onGoBackStep,
  onGoToNextStep,
  project,
}: IStepProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const channels = project?.channels || [];

  const initialValues = useMemo(() => {
    if (!channels?.length) {
      return {};
    }
    return channels?.reduce((hash: any, { id, additionalParameters }) => {
      if (additionalParameters) {
        return {
          ...hash,
          ...Object.keys(additionalParameters).reduce(
            (hash: { [key: string]: Value[] }, propTitle: string) => {
              hash[`${id}#${propTitle}`] = [{ value: '' }];
              return hash;
            },
            {},
          ),
        };
      }
    }, {});
  }, [JSON.stringify(project?.channels)]);

  return (
    <div className={styles.root}>
      <div className={styles.root__container}>
        <BackBtn onGoBackStep={onGoBackStep} />
        <div className={styles.root__content}>
          <h2>Step 5/5. Add additional parameters of traffic channels</h2>

          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              const valuesObject = Object.entries(values).reduce(
                (hash: { [key: string]: any }, [key, v]: any) => {
                  const [channelId, prop] = key.split('#');
                  if (!hash[channelId]) {
                    hash[channelId] = {};
                  }
                  hash[channelId][prop] = v
                    .map((el: { [key: string]: string }) => el.value)
                    .filter((v: string) => v);
                  return hash;
                },
                {},
              );
              const channelList = channels?.map((channel) => {
                const additionalParameters = valuesObject[channel.id];
                if (additionalParameters) {
                  return {
                    ...channel,
                    additionalParameters,
                  };
                } else {
                  return {
                    ...channel,
                    additionalParameters: [],
                  };
                }
              });
              onStepFinish(channelList);
              setOpen(true);
            }}
            render={({ values }) => (
              <Form className={styles.formContent}>
                <div className={styles.items}>
                  <div className={styles.itemsRow}>
                    {channels?.map((channel, index) => (
                      <ChannelParamsV2
                        key={channel.id}
                        field={channel.id.toString()}
                        title={channel.title}
                        icon={channel.icon ? `${AppConfig.UTM_IMAGE_URL}${channel.icon}` : ''}
                        values={values}
                        defaultHide={!!index}
                        channelProps={channel.additionalParameters}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.actions}>
                  <SkipButton onClick={onSkip} />
                  <SubmitButton>Ready</SubmitButton>
                </div>
              </Form>
            )}
          />

          <ConfirmModal
            open={open}
            onClose={() => setOpen(false)}
            onNext={() => {
              setOpen(false);
              onGoToNextStep();
            }}
            title='Thank you!'
            text='All fields are set, you may proceed to your profile and continue with your settings.'
            robot={robotHappyIcon}
          />
        </div>
      </div>
    </div>
  );
};
