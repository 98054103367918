import cx from 'classnames';
import styles from './styles.module.scss';
import translations from '../../consts/translations';
import { AppConfig } from '../../config';
import { LanguagesEnum } from '../../common/language.type';
import { useContext } from 'react';
import { LanguageContext } from 'layouts/HomeLayout';
import { useLocation } from 'react-router-dom';
import { Pages } from 'containers/routers';

const FooterBottom = () => {
  const location = useLocation();
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const year = new Date().getFullYear();

  return (
    <div className={styles.footerBottom}>
      <div className={styles.footerBottom__copyright}>
        {year} - {translations[language].footer.copyright}
      </div>
      <div className={styles.footerBottom__contacts}>
        <div className={styles.footerBottom__location}>
          <span>
            <img
              className={styles.footerBottom__locationIcon}
              src={`${AppConfig.IMAGE_URL}/blog/img/footer/location_icon.svg`}
              alt='Location Icon'
            />
            <a
              target='_blank'
              rel='noreferrer'
              className={styles.footerBottom__locationAddress}
              href='https://goo.gl/maps/zBMZtQqRUBvVGx1f9'
            >
              <span className={styles.footerBottom__country}>
                {translations[language].footer.country}
              </span>
            </a>
          </span>
          <span>
            <img
              className={styles.footerBottom__locationIcon}
              src={`${AppConfig.IMAGE_URL}/blog/img/footer/location_icon.svg`}
              alt='Location Icon'
            />
            <a
              target='_blank'
              rel='noreferrer'
              className={styles.footerBottom__locationAddress}
              href='https://goo.gl/maps/bUMex8ayGa8usH449'
            >
              <span className={styles.footerBottom__country}>
                {translations[language].footer.country2}
              </span>
            </a>
          </span>
        </div>

        <div className={styles.footerBottom__phones}>
          <img
            className={styles.footerBottom__phoneIcon}
            src={`${AppConfig.IMAGE_URL}/blog/img/footer/phone_icon.svg`}
            alt='Phone Icon'
          />
          <a className={styles.footerBottom__phone} href='tel:0800333235'>
            0800 333 235
          </a>
        </div>
      </div>
      <div className={styles.footerBottom__langs}>
        {language === LanguagesEnum.UK ? (
          <span
            className={cx({
              [styles.footerBottom__lang]: true,
              [styles['footerBottom__lang--selected']]: true,
            })}
          >
            {translations[language].footer.languageUK}
          </span>
        ) : (
          <a
            href={`${
              window.location.href.endsWith('/')
                ? window.location.href.slice(0, -1)
                : window.location.href
            }/uk`}
            className={cx({
              [styles.footerBottom__lang]: true,
            })}
          >
            {translations[language].footer.languageUK}
          </a>
        )}
        <div className={styles.footerBottom__langDivider} />
        {language === LanguagesEnum.EN ? (
          <span
            className={cx({
              [styles.footerBottom__lang]: true,
              [styles['footerBottom__lang--selected']]: true,
            })}
          >
            {translations[language].footer.languageEN}
          </span>
        ) : (
          <a
            href={`${window.location.href.substring(0, window.location.href.lastIndexOf('/uk'))}`}
            className={cx({
              [styles.footerBottom__lang]: true,
            })}
          >
            {translations[language].footer.languageEN}
          </a>
        )}
      </div>
    </div>
  );
};

export default FooterBottom;
