import styles from './styles.module.scss';
import { TalkBubbleShapeV1 } from '../../talk-bubble-shapes/talk-bubble-shape-v1';
import { ReactComponent as RobotImage } from '../../../assets/img/robot.svg';

type InputProps = {
  title: string;
};

export const CreateProjectRobot = ({ title }: InputProps) => {
  return (<div className={styles.root}>
    <TalkBubbleShapeV1 title={title}/>
    <RobotImage/>
  </div>);
};
