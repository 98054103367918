import { Button } from '@mui/material';

import styles from './styles.module.scss';

type InputProps = {
  disabled?: boolean;
  onClick: () => void;
  children: string;
};

const TextButton = ({ disabled, onClick, children }: InputProps) => {
  return (
    <Button
      disabled={disabled}
      variant="text"
      className={styles.btn}
      onClick={onClick}
    >{children}</Button>
  );
};

export default TextButton;
