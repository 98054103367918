const translations: { [key: string]: any } = {
  uk: {
    header: {
      categories: {
        title: 'Блог',
        seo: 'SEO',
        'crowd-marketing': 'Крауд маркетинг',
        'link-building': 'Побудова посилань',
        'google-ads': 'Google Ads',
        tips: 'Поради',
      },
      navigation: {
        home: 'Головна',
        products: 'Продукти',
        projects: 'Проекти',
        blog: 'Блог',
      },
      languageUK: 'Укр',
      languageEN: 'Анг',
      button: 'ЗВ’ЯЗАТИСЯ З НАМИ',
    },
    toolbar: {
      breadcrumbs: {
        seo: 'SEO',
        'crowd-marketing': 'Крауд маркетинг',
        'link-building': 'Побудова посилань',
        'google-ads': 'Google Ads',
        tips: 'Поради',
      },
      mainPage: 'Блог',
      search: 'ПОШУК',
    },
    mainPost: {
      read: 'чит.',
    },
    subscribe: {
      title: 'Дай мені останні новини!',
      text: 'Хочете знати, які веб-сайти зафіксували найбільше зростання трафіку у вашій галузі? Не знаєте, чому ваша стратегія SEO не працює? Або просто шукаєте поради щодо SEO? Підпишіться на нашу розсилку, щоб отримувати оновлення про вміст, який вас цікавить.',
      email: 'ЕМЕЙЛ',
      button: 'ПІДПИСАТИСЯ',
      terms:
        'Натискаючи «Підписатися», ви погоджуєтеся з Політикою конфіденційності та даєте згоду на використання ваших контактних даних для розсилки новин',
      follow: 'АБО СЛІДКУЙ ЗА НАМИ У:',
      emailValidation: 'Будь ласка вкажить правельний емейл',
    },
    footer: {
      copyright: 'Всі права захищені.',
      country: 'Київ, Україна',
      country2: 'Лондон, Велика Британія',
      languageUK: 'Українська',
      languageEN: 'Англійська',
    },
    post: {
      share: 'ПОДІЛИТИСЯ',
      read: 'чит.',
    },
    pages: {
      home: {
        lastPosts: 'ОСТАННІ ПОСТИ',
        seo: 'SEO',
      },
    },
    contacts: {
      phone: "Зв'язатися з нами:",
      messangers: 'Написати нам в мессенджери:',
    },
    maintenance: 'Блог у розробці',
  },

  en: {
    header: {
      categories: {
        title: 'Blog',
        seo: 'SEO',
        'crowd-marketing': 'Crowd marketing',
        'link-building': 'Link building',
        'google-ads': 'Google Ads',
        tips: 'Tips',
      },
      navigation: {
        home: 'Home',
        products: 'Products',
        projects: 'Projects',
        blog: 'Blog',
      },
      languageUK: 'Ukr',
      languageEN: 'Eng',
      button: 'CONTACT US',
    },
    toolbar: {
      breadcrumbs: {
        seo: 'SEO',
        'crowd-marketing': 'Crowd marketing',
        'link-building': 'Link building',
        'google-ads': 'Google Ads',
        tips: 'Tips',
      },
      mainPage: 'Blog',
      search: 'SEARCH',
    },
    mainPost: {
      read: 'read',
    },
    subscribe: {
      title: 'Give me the latest news!',
      text: 'Want to know which websites saw the most traffic growth in your industry? Not sure why your SEO strategy doesn’t work? Or simply looking for SEO tips? Subscribe to our newsletter to receive updates on the content you care about.',
      email: 'YOUR  EMAIL',
      button: 'SUBSCRIBE',
      terms:
        'By clicking “Subscribe” you agree to Privacy Policy and consent  using your contact data for newsletter purposes',
      follow: 'OR FOLLOW ON:',
      emailValidation: 'Please enter a valid email',
    },
    footer: {
      copyright: 'All rights reserved. ',
      country: 'Kyiv, Ukraine',
      country2: 'London, UK',
      languageUK: 'Ukrainian',
      languageEN: 'English',
    },
    post: {
      share: 'SHARE',
      read: 'read',
    },
    pages: {
      home: {
        lastPosts: 'LAST ARTICLES',
        seo: 'SEO',
      },
    },
    contacts: {
      phone: 'Contact us:',
      messangers: 'Write us on messangers:',
    },
    maintenance: 'Blog is under contstruction',
  },
};

export default translations;
