import { IconButton } from '@mui/material';

import { ReactComponent as AddButtonIcon } from 'assets/img/add-button.svg';

import styles from './sryles.module.scss';

type InputProps = {
  onClick: () => void;
};

export const AddButton = ({ onClick }: InputProps) => {
  return (
    <IconButton onClick={onClick} className={styles.root}>
      <AddButtonIcon/>
    </IconButton>
  );
};
