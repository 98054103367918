import React, { ReactNode, useState } from 'react';
import { Field } from 'formik';
import { ReactSVG } from 'react-svg';

import { ReactComponent as ArrowIcon } from 'assets/img/select-arrow-2.svg';
import styles from './styles.module.scss';
import { AddButton } from '../../buttons/add-button';
import { IconButton } from '@mui/material';
import clsx from 'clsx';

export const emptyValue = {
  agencyName: '',
  responsibleName: '',
};

export interface IChannelParam {
  agencyName: string;
  responsibleName: string;
}

type InputProps = {
  title: string;
  field: string;
  icon?: string;
  values?: any;
  arrayHelpers?: any;
  defaultHide?: boolean;
  placeholderName: string;
  placeholderValue: string;
};

export const ChannelParams = ({
  title,
  field,
  icon,
  values,
  arrayHelpers,
  defaultHide = true,
  placeholderName,
  placeholderValue,
}: InputProps) => {
  const [hide, setHide] = useState(defaultHide);

  const toggleHandler = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <div className={clsx(styles.root, hide && styles.hide)}>
      <div className={styles.header}>
        <IconButton
          className={clsx(styles.arrowBtn, hide && styles.arrowBtnHide)}
          onClick={toggleHandler}
        >
          <ArrowIcon />
        </IconButton>
        {icon && <div className={styles.icon}>{<img src={icon} />}</div>}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={clsx(hide && styles.hide)}>
        {values[field].map((item: any, index: any) => (
          <div key={index}>
            <Field name={`${field}.${index}.agencyName`} placeholder={placeholderName} />
            <Field name={`${field}.${index}.responsibleName`} placeholder={placeholderValue} />
          </div>
        ))}
        <div className={styles.actions}>
          <AddButton
            onClick={() => {
              arrayHelpers.push({
                agencyName: '',
                responsibleName: '',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
