import React, { FC } from 'react';
import { Box, Select, MenuItem } from '@mui/material';

import { BaseUrlInputProps } from './base-url-input.props';
import './base-url.scss';
import { inputNullingStyles } from '../../common/input-nulling-styles';

export const BaseUrlInput: FC<BaseUrlInputProps> = ({
  selectHandler,
  selectValue,
  textInputValue,
  textInputHandler,
}) => {
  return (
    <Box className='base-url-wrapper'>
      <Box className='base-url-select'>
        <Select
          sx={{
            ...inputNullingStyles,
          }}
          onChange={(e) => selectHandler(e.target.value)}
          value={selectValue}
        >
          <MenuItem value={'http://'}>http://</MenuItem>
          <MenuItem value={'https://'}>https://</MenuItem>
        </Select>
      </Box>
      <Box className='base-url-input-wrapper'>
        <input
          type='text'
          className='base-input'
          value={textInputValue}
          placeholder='Add your URL address to start the analysis'
          onChange={(e) => textInputHandler(e.target.value)}
        />
      </Box>
    </Box>
  );
};
