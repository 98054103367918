import React, { useCallback, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from '@mui/material';

import SkipButton from 'components/buttons/skip-button';
import SubmitButton from 'components/buttons/main-button/submit';
import TextButton from 'components/buttons/text-button';

import styles from './styles.module.scss';
import ConfirmModal from '../../confirm-modal';
import { IStepProps } from '../istep.props';
import { IChannelDto } from '../data/channels';
import { ReactComponent as selectArrow } from 'assets/img/select-arrow.svg';
import { ReactComponent as SelectedIcon } from 'assets/img/selected.svg';
import { AppConfig } from 'config';
import { BackBtn } from '../../backBtn/backBtn';

type InputProps = {
  adsCabinetChannels: IChannelDto[];
  smmChannels: IChannelDto[];
  otherChannels: IChannelDto[];
  selectedChannelsByDefault?: IChannelDto[];
};

const MenuProps = {
  PaperProps: {
    style: {
      width: '100%',
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};

export const ChooseChannel = ({
  onStepFinish,
  onSkip,
  onGoToNextStep,
  onGoBackStep,
  adsCabinetChannels,
  smmChannels,
  otherChannels,
  selectedChannelsByDefault = [],
}: IStepProps & InputProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [selectedChannels, setSelectedChannels] =
    useState<IChannelDto[]>(selectedChannelsByDefault);
  const matches500 = useMediaQuery('(max-width:500px)');

  const channelList = useMemo(() => {
    return [...adsCabinetChannels, ...smmChannels, ...otherChannels].reduce(
      (hash: { [key: number]: IChannelDto }, ch) => {
        hash[ch.id] = ch;
        return hash;
      },
      {},
    );
    // eslint-disable-next-line
  }, [
    JSON.stringify(adsCabinetChannels),
    JSON.stringify(smmChannels),
    JSON.stringify(otherChannels),
  ]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setOpen(true);
    onStepFinish(selectedChannels);
  };

  const handleChange = (channel: IChannelDto) => {
    isSelected(channel.id)
      ? setSelectedChannels((prevState) => [...prevState.filter((ch) => ch.id !== channel.id)])
      : setSelectedChannels((prevState) => [...prevState, channel]);
  };

  const handleChangeSelect = (values: number[]) => {
    const selected = values.map((v) => channelList[v]);
    setSelectedChannels(selected);
  };

  // eslint-disable-next-line
  const isSelected = useCallback(
    (channelId: number) => !!selectedChannels.find((ch) => ch.id === channelId),
    [JSON.stringify(selectedChannels)],
  );

  return (
    <div className={styles.root}>
      <div className={styles.root__container}>
        <BackBtn onGoBackStep={onGoBackStep} />
        <h2>Step 1/5. Choose advertisement channel</h2>
        <form onSubmit={handleSubmit}>
          {!matches500 ? (
            <div className={styles.formColumns}>
              <FormControl className={styles.col1}>
                <FormLabel component='legend'>Channels with ads cabinet:</FormLabel>
                <FormGroup>
                  {adsCabinetChannels.map((channel) => (
                    <FormControlLabel
                      key={channel.id}
                      control={
                        <Checkbox
                          checked={isSelected(channel.id)}
                          onChange={() => handleChange(channel)}
                          name={channel.title}
                        />
                      }
                      label={
                        <span className={styles.checkboxLabel}>
                          {channel.icon && (
                            <img src={AppConfig.UTM_IMAGE_URL + channel.icon} alt='' />
                          )}
                          <span>{channel.title}</span>
                        </span>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <FormControl className={styles.col2}>
                <FormLabel component='legend'>SMM:</FormLabel>
                <FormGroup>
                  {smmChannels.map((channel) => (
                    <FormControlLabel
                      key={channel.id}
                      control={
                        <Checkbox
                          checked={isSelected(channel.id)}
                          onChange={() => handleChange(channel)}
                          name={channel.title}
                        />
                      }
                      label={
                        <span className={styles.checkboxLabel}>
                          {channel.icon && (
                            <img src={AppConfig.UTM_IMAGE_URL + channel.icon} alt='' />
                          )}
                          <span>{channel.title}</span>
                        </span>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <FormControl className={styles.col3}>
                <FormLabel component='legend'>Other channels:</FormLabel>
                <FormGroup>
                  {otherChannels.map((channel) => (
                    <FormControlLabel
                      key={channel.id}
                      control={
                        <Checkbox
                          checked={isSelected(channel.id)}
                          onChange={() => handleChange(channel)}
                          name={channel.title}
                        />
                      }
                      label={
                        <span className={styles.checkboxLabel}>
                          {channel.icon && (
                            <img src={AppConfig.UTM_IMAGE_URL + channel.icon} alt='' />
                          )}
                          <span>{channel.title}</span>
                        </span>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          ) : (
            <>
              <FormControl>
                <Select
                  multiple
                  displayEmpty
                  id='grouped-select'
                  label='Choose channel'
                  value={selectedChannels.map((ch) => ch.id)}
                  onChange={(e) => handleChangeSelect(e.target.value as number[])}
                  open={openSelect}
                  onOpen={() => {
                    setOpenSelect(true);
                  }}
                  onClose={() => {
                    setOpenSelect(false);
                  }}
                  input={<OutlinedInput />}
                  renderValue={() => {
                    return <span>Choose channel</span>;
                  }}
                  className={styles.selectChannel}
                  IconComponent={selectArrow}
                  MenuProps={MenuProps}
                >
                  <ListSubheader className={styles.listSubheader}>With ads cabinet:</ListSubheader>
                  {adsCabinetChannels.map((channel) => (
                    <MenuItem className={styles.itemMenu} key={channel.id} value={channel.id}>
                      <Checkbox className={styles.checkboxMenu} checked={isSelected(channel.id)} />
                      {channel.icon && <img src={process.env.PUBLIC_URL + channel.icon} alt='' />}
                      {channel.title}
                    </MenuItem>
                  ))}
                  <ListSubheader className={styles.listSubheader}>SMM:</ListSubheader>
                  {smmChannels.map((channel) => (
                    <MenuItem className={styles.itemMenu} key={channel.id} value={channel.id}>
                      <Checkbox className={styles.checkboxMenu} checked={isSelected(channel.id)} />
                      {channel.icon && <img src={process.env.PUBLIC_URL + channel.icon} alt='' />}
                      {channel.title}
                    </MenuItem>
                  ))}
                  <ListSubheader className={styles.listSubheader}>
                    Without ads cabinet:
                  </ListSubheader>
                  {otherChannels.map((channel) => (
                    <MenuItem className={styles.itemMenu} key={channel.id} value={channel.id}>
                      <Checkbox className={styles.checkboxMenu} checked={isSelected(channel.id)} />
                      {channel.icon && <img src={process.env.PUBLIC_URL + channel.icon} alt='' />}
                      {channel.title}
                    </MenuItem>
                  ))}
                  <div className={styles.selectAction}>
                    <TextButton
                      onClick={() => {
                        setOpenSelect(false);
                      }}
                    >
                      Close
                    </TextButton>
                  </div>
                </Select>
              </FormControl>
              <ul className={styles.selectedChannels}>
                {selectedChannels.map((channel) => (
                  <li key={channel.id}>
                    <SelectedIcon />{' '}
                    {channel.icon && <img src={process.env.PUBLIC_URL + channel.icon} alt='' />}
                    {channel.title}
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className={styles.note}>*Please choose at least one channel</div>
          <div className={styles.actions}>
            <SkipButton onClick={onSkip} />
            <SubmitButton>Ready</SubmitButton>
          </div>
        </form>
        <ConfirmModal
          open={open}
          onClose={() => setOpen(false)}
          onNext={() => {
            setOpen(false);
            onGoToNextStep();
          }}
          title='Now add responsibles for each channel'
          text='This will help you organize your work better.'
        />
      </div>
    </div>
  );
};
