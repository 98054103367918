import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import './home-page-wrapper.scss';
import {
  UtmGeneratorMainFormComponent,
  InfoBlock,
  MainSlider,
  LinksBlock,
} from '../../components';
import ellipse from '../../assets/img/ellipses/ellipse-1.svg';
import ContentService from '../../api/services/content/content.service';
import IContent from '../../api/services/content/interfaces/content.interface';
import Footer from '../../components/footer';
import Header from '../../components/header/Header';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const snackbar = useSnackbar();
  const [data, setData] = useState<IContent | undefined>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lang = params.get('lang') ? 'UKR' : 'ENG';

  useEffect(() => {
    ContentService.getContent(lang)
      .then((content) => {
        setData(content);
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, []);


  return (
    <Box className="home-page-wrapper">
      <div className="first-background"/>
      <img src={ellipse} className="first-ellipse"/>
      <Header />
      <Grid container className="content-home-page">
        <Grid item xs={12}>
          <Typography align="center" className="content-home-page home-title">
            {data?.texts.get('utm_builder')?.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className="content-home-page home-sub-title">
            {data?.texts.get('utm_builder')?.text}
          </Typography>
        </Grid>
        <Grid item className="content-home-page calculator-wrapper">
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <UtmGeneratorMainFormComponent channels={data?.channels || []}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InfoBlock
        title={data?.texts.get('about')?.title || ''}
        text={data?.texts.get('about')?.text || ''}
        options={[
          data?.texts.get('about1')?.text || '',
          data?.texts.get('about2')?.text || '',
          data?.texts.get('about3')?.text || '',
          data?.texts.get('about4')?.text || ''
        ]}
      />
      <MainSlider parameters={data?.parameters || []}/>
      <LinksBlock
        title={data?.texts.get('tell_about')?.title || ''}
        text={data?.texts.get('tell_about')?.text || ''}
      />
      <Footer/>
    </Box>
  );
};

export default HomePage;
