import styles from './params.module.scss';

import { IProject } from 'modules/project/interfaces/project';
import { useMemo, useState } from 'react';
import { ChannelParamsV2 } from 'components/forms/channel-params-v2';
import { AppConfig } from 'config';
import { Form, Formik } from 'formik';

interface IParamsPropsTypes {
  project?: IProject;
}

type Value = {
  value: string;
};

export const Params = ({ project }: IParamsPropsTypes): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const channels = project?.channels || [];

  const initialValues = useMemo(() => {
    if (!channels?.length) {
      return {};
    }
    return channels?.reduce((hash: any, { id, additionalParameters }) => {
      if (additionalParameters) {
        return {
          ...hash,
          ...Object.keys(additionalParameters).reduce(
            (hash: { [key: string]: Value[] }, propTitle: string) => {
              hash[`${id}#${propTitle}`] = [{ value: '' }];
              return hash;
            },
            {},
          ),
        };
      }
    }, {});
  }, [JSON.stringify(project?.channels)]);
  return (
    <div className={styles.params}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const valuesObject = Object.entries(values).reduce(
            (hash: { [key: string]: any }, [key, v]: any) => {
              const [channelId, prop] = key.split('#');
              if (!hash[channelId]) {
                hash[channelId] = {};
              }
              hash[channelId][prop] = v
                .map((el: { [key: string]: string }) => el.value)
                .filter((v: string) => v);
              return hash;
            },
            {},
          );
          const channelList = channels?.map((channel) => {
            const additionalParameters = valuesObject[channel.id];
            if (additionalParameters) {
              return {
                ...channel,
                additionalParameters,
              };
            } else {
              return {
                ...channel,
                additionalParameters: [],
              };
            }
          });
          setOpen(true);
        }}
        render={({ values }) => (
          <Form className={styles.formContent}>
            <div className={styles.items}>
              <div className={styles.itemsRow}>
                {channels?.map((channel, index) => (
                  <ChannelParamsV2
                    key={channel.id}
                    field={channel.id.toString()}
                    title={channel.title}
                    icon={channel.icon ? `${AppConfig.UTM_IMAGE_URL}${channel.icon}` : ''}
                    values={values}
                    defaultHide={!!index}
                    channelProps={channel.additionalParameters}
                    isCabinet
                  />
                ))}
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};
