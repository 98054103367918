import { ContentDto } from './dto/content.dto';
import Api from '../../api';
import { routes } from '../../routes';
import { mockdata as data} from './mock';
import { getContentTextMapper } from './mapper';
import IContent from './interfaces/content.interface';

export default class ContentService {
  static async getContent(lang: 'UKR' | 'ENG' = 'ENG'): Promise<IContent | undefined> {
    try {
      // const data = await Api.get<ContentDto>(routes.CONTENT, { lang });
      return {
        ...data,
        texts: getContentTextMapper(data.texts)
      };
    } catch (error) {
      throw new Error('Error getting data. Please refresh the page.');
    }
  };
};
