import { useEffect, useState, useRef, useContext } from 'react';
import cx from 'classnames';
import translations from '../../../consts/translations';
import styles from './languages.module.scss';
import { AppConfig } from 'config';
import useModalOutsideClick from '../useModalOutsideClick';
import { useLocation } from 'react-router-dom';
import { LanguagesEnum } from '../../../common/language.type';
import { LanguageContext } from 'layouts/HomeLayout';
import { Pages } from 'containers/routers';

const Languages = () => {
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const location = useLocation();
  const buttonRef = useRef(null);
  const [openLangs, setOpenLangs] = useState(false);
  useModalOutsideClick(buttonRef, setOpenLangs);

  // useEffect(() => {
  //   setOpenLangs(false)
  // }, [router.locale])

  return (
    <div className={styles.lang}>
      <img
        className={styles.lang__icon}
        src={`${AppConfig.IMAGE_URL}/blog/img/lang_icon.svg`}
        alt='language icon'
      />
      <button
        ref={buttonRef}
        type='button'
        className={styles.lang__button}
        onClick={() => setOpenLangs(!openLangs)}
      >
        {language === LanguagesEnum.EN
          ? translations[language].header.languageEN
          : translations[language].header.languageUK}
        <img
          className={cx({
            [styles.lang__arrowDown]: true,
            [styles['lang__arrowDown--rotated']]: openLangs,
          })}
          src={`${AppConfig.IMAGE_URL}/blog/img/arrow_down.svg`}
          alt='arrow down'
        />
      </button>

      {openLangs && (
        <div className={styles.lang__dialog}>
          {language === LanguagesEnum.EN ? (
            <span
              className={cx({
                [styles.lang__link]: true,
                [styles['lang__link--selected']]: true,
              })}
            >
              {translations[language].header.languageEN}
            </span>
          ) : (
            <a
              href={`${window.location.href.substring(0, window.location.href.lastIndexOf('/uk'))}`}
              className={cx({
                [styles.lang__link]: true,
              })}
            >
              {translations[language].header.languageEN}
            </a>
          )}
          {language === LanguagesEnum.UK ? (
            <span
              className={cx({
                [styles.lang__link]: true,
                [styles['lang__link--selected']]: true,
              })}
            >
              {translations[language].header.languageUK}
            </span>
          ) : (
            <a
              href={`${
                window.location.href.endsWith('/')
                  ? window.location.href.slice(0, -1)
                  : window.location.href
              }/uk`}
              className={cx({
                [styles.lang__link]: true,
              })}
            >
              {translations[language].header.languageUK}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Languages;
