import { FieldArray, Form, Formik } from 'formik';
import styles from './responsibles.module.scss';
import { InputTable } from 'components/inputs/input-table';
import { AddButton } from 'components/buttons/add-button';
import { v4 as uuid } from 'uuid';
import { FormEvent, useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';

const emptyResponsible = {
  name: '',
  id: uuid(),
};

interface IResponsible {
  agencyName: string;
  responsibleName: string;
}

interface IResponsiblePropsTypes {
  responsibleData: IResponsible[];
}

interface IEmptyResponsible {
  name: string;
  id: string;
}

export const Responsibles = ({ responsibleData }: IResponsiblePropsTypes): JSX.Element => {
  const [inputsData, setInputsData] = useState<IEmptyResponsible[]>([emptyResponsible]);

  useEffect(() => {
    if (responsibleData?.length > 0) {
      const newInputsData = responsibleData.map((el: IResponsible) => {
        return { name: el.responsibleName, id: uuid() };
      });
      setInputsData(newInputsData);
    }
  }, [responsibleData]);

  const onchangeInput = (e: FormEvent<HTMLInputElement>, index: string) => {
    const newInputsData = inputsData.map((item) => {
      if (item.id === index) {
        return { ...item, name: e.currentTarget.value };
      }
      return item;
    });
    setInputsData(newInputsData);
  };
  return (
    <div className={styles.responsibles}>
      {inputsData?.map((el: IEmptyResponsible) => {
        return (
          <div key={el.id} className={styles.responsibles__item}>
            <input
              type='text'
              className={styles.responsibles__input}
              placeholder='Імя відповідального'
              value={el.name}
              onChange={(e) => onchangeInput(e, el.id)}
            />
            <TrashIcon
              className={styles.responsibles__delete}
              onClick={() => {
                const newInputData = inputsData.filter((item) => item.id != el.id);
                setInputsData(newInputData);
              }}
            />
          </div>
        );
      })}
      <button
        onClick={() => {
          const newInputData = [
            ...inputsData,
            {
              name: '',
              id: uuid(),
            },
          ];
          setInputsData(newInputData);
        }}
        className={styles.responsibles__add}
      >
        <span className={styles.responsibles__add_plus}>+</span> Додати відповідального
      </button>
    </div>
  );
};
