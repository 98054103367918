import SkipButton from '../buttons/skip-button';
import InputWithButton from '../inputs/input-with-button';

import styles from './styles.module.scss';
import { useMediaQuery } from '@mui/material';

type InputProps = {
  disabled?: boolean;
  onSkip: () => void;
  onCreate: (projectName: string) => void;
};

const CreateProjectForm = ({ disabled, onSkip, onCreate }: InputProps) => {
  const matches500 = useMediaQuery('(max-width:500px)');

  return (
    <div className={styles.root}>
      {matches500
        ?
        <h2 className={styles.title}>Create a project <br/> to merge links within it</h2>
        :
        <h2 className={styles.title}>Create a project to merge links within it</h2>
      }
      <div className={styles.form}>
        <InputWithButton
          disabled={disabled}
          placeholder="Add project name"
          buttonName="Create"
          onGetValue={onCreate}
        />
        <SkipButton onClick={onSkip} disabled={disabled}/>
      </div>
    </div>
  );
};

export default CreateProjectForm;
