import Api from '../../api';
import { routes } from '../../routes';
import { IProject } from '../../../modules/project/interfaces/project';

export default class ProjectService {
  static async createProject(project: IProject): Promise<void> {
    try {
      const data = await Api.post<IProject, any>(routes.CREATE_PROJECT, project);
    } catch (error) {
      throw new Error('Error save data. Please refresh the page.');
    }
  }
  static async getProject(lang: 'UKR' | 'ENG' = 'ENG', id: string): Promise<any | undefined> {
    try {
      const data = await Api.get<any>(routes.GET_PROJECT, { id, lang });
      return data;
    } catch (error) {
      throw new Error('Error getting data. Please refresh the page.');
    }
  }
  static async getProjects(): Promise<any | undefined> {
    try {
      const data = await Api.get<any>(routes.GET_PROJECTS);
      return data;
    } catch (error) {
      throw new Error('Error getting data. Please refresh the page.');
    }
  }
}
