import styles from './styles.module.scss';
import FooterBottom from './FooterBottom'
import FooterTop from './FooterTop'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <FooterTop />
      <div className={styles.footer__line} />
      <FooterBottom />
    </footer>
  )
}

export default Footer
