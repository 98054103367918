import React from 'react';
import checkIcon from 'assets/img/check.svg';
import InfoModal from 'components/modals/info-modal';
import styles from './styles.module.scss';
import MainButton from '../../../../components/buttons/main-button';

type InputProps = {
  open: boolean
  onClose: () => void;
  onNext: () => void;
  projectName: string;
};

const ProjectCreatedModal = ({
  projectName,
  open,
  onClose,
  onNext
}: InputProps) => {
  return (
    <InfoModal
      ellipseTop={true}
      open={open}
      onClose={onClose}
    >
      <div className={styles.root}>
        <img src={checkIcon} alt="" className={styles.iconCheck}/>
        <div className={styles.title}>The Project ‘{projectName}’ was successfully created!</div>
        <MainButton onClick={onNext} mobileSensitive={true}>Next</MainButton>
      </div>
    </InfoModal>
  );
};

export default ProjectCreatedModal;
