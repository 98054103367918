import { useState, KeyboardEvent } from 'react';
import { Button, InputBase } from '@mui/material';

import styles from './styles.module.scss';

type InputProps = {
  initValue?: string;
  placeholder?: string;
  disabled?: boolean;
  buttonName: string;
  onGetValue: (v: string) => void;
};

const InputWithButton = ({
  initValue = '',
  placeholder = '',
  disabled,
  buttonName,
  onGetValue
}: InputProps) => {
  const [value, setValue] = useState<string>(initValue);

  const onGetValueHandler = () => {
    onGetValue(value);
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter' && value.toString()) {
      onGetValue(value);
    }
  }

  return (
    <div className={styles.root}>
      <InputBase
        disabled={disabled}
        className={styles.input}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={keyPressHandler}
      />
      <Button disabled={disabled || !value} className={styles.btn} onClick={onGetValueHandler}>{buttonName}</Button>
    </div>
  );
};

export default InputWithButton;
