export const Pages = {
  base: '/',
  home: '/utm-builder',
  login: '/utm-builder/login',
  cabinet: '/utm-builder/cabinet', // profit.store/utm-bilder/cabinet
  cabinetAdvertisingChannels: '/utm-builder/cabinet/advertising-channels',
  cabinetProducts: '/utm-builder/cabinet/products',
  cabinetCreatives: '/utm-builder/cabinet/creatives',
  cabinetProjects: '/utm-builder/cabinet/projects',
  cabinetSettings: '/utm-builder/cabinet/settings',
};
