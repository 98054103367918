import React from 'react';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import TopAppBar from './cabinet/top-app-bar';
import { Nav } from './cabinet/nav';
import styles from './CabinetLayout.module.scss';

interface CabinetLayoutProps {
  children: JSX.Element;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      contrastText: '#242427',
    },
    secondary: {
      main: '#FDFDFD',
      contrastText: '#676D77',
    },
    background: {
      default: '#FDFDFD',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
      :root {
        --main-color: #242427;
      }
        * {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          color: ${themeParam.palette.primary.contrastText};
        }
        input {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: ${themeParam.palette.primary.contrastText};
        }
        input::placeholder {
         color: #B3B3B3;
        }
        h6, h5, h4, h3, h2, h1 {
          width: 100%;
          font-family: 'Inter';
          font-weight: 600;
          color: ${themeParam.palette.primary.contrastText};
        }
        h1 {
          font-weight: 600;
          font-size: 32px;
          line-height: 39px;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: ${themeParam.palette.secondary.contrastText};
        }
      `,
    },
  },
});

export default function CabinetLayout({ children }: CabinetLayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <header>
          <TopAppBar />
        </header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <nav
            style={{
              background: theme.palette.primary.main,
              boxShadow: '0px 16px 40px rgba(112, 144, 176, 0.2)',
              width: '100%',
              maxWidth: '216px',
              height: 'calc(100vh - 64px)',
            }}
            className={styles.navbar}
          >
            <Nav />
          </nav>
          <section
            style={{
              background: theme.palette.secondary.main,
              width: '100%',
              padding: '27px 23px 30px',
            }}
          >
            {children}
          </section>
        </div>
      </Box>
    </ThemeProvider>
  );
}
