import React from 'react';
import { Button } from '@mui/material';

import styles from './styles.module.scss';
import clsx from 'clsx';

type InputProps = {
  children: string;
  onClick: () => void;
  className?: string;
  mobileSensitive?: boolean;
};
const MainButton = ({ children, onClick, className, mobileSensitive }: InputProps) => {
  return (
    <Button className={clsx(styles.root, mobileSensitive && styles.mobile, className)} onClick={onClick}>{children}</Button>
  );
};

export default MainButton;
