import React, { useEffect, useState } from 'react';
import {
  Select,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  ListSubheader,
  OutlinedInput,
} from '@mui/material';

import { hoc, windowSize, WindowSizeProps } from '../../hoc';
import { useDropdownHook } from './use-dropdown.hook';
import './dropdown.scss';
import cn from 'classnames';
import { DropdownHookProps, DropdownProps } from './dropdown.props';
import { mdLineWidth } from '../../common/constants';
import IChannel, { IChannelItem } from '../../api/services/content/interfaces/channel.interface';
import { ApiConfig } from '../../api/config';

const selectStyle = {
  color: '#06437C',
  width: '100%',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0, boxShadow: 'none' },
  '&:hover': {
    border: '0px solid black',
  },
  '&.Mui-focused': {
    color: '#FF9900',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '0px solid #484850',
    borderRadius: '5px 5px 0 0',
  },
};

const formLabel = {
  color: '#000',
  '&.Mui-focused': {
    color: '#FF9900',
  },
};

const getItems = (channels: IChannelItem[]) =>
  channels.map((channel) => {
    return (
      <MenuItem key={channel.name} value={channel.name} sx={{ display: 'flex' }}>
        <div style={{ display: 'flex' }}>
          <img src={`${ApiConfig.API_URL}${channel.icon}`} alt='img not found' />
          <p
            style={{
              color: '#565960',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              marginLeft: '5px',
            }}
          >
            {channel.name}
          </p>
        </div>
      </MenuItem>
    );
  });

type InputProps = {
  channels: IChannel[];
  isCabinet?: boolean;
};

export const Dropdown = windowSize(
  hoc<WindowSizeProps & DropdownProps & InputProps, DropdownHookProps>(
    useDropdownHook,
    ({
      valueWithAbs,
      valueSmm,
      valueOther,
      setValueWithAbs,
      setValueSmm,
      setValueOther,
      width,
      channels,
      isCabinet,
    }) => {
      const mapper = (category: string) => {
        switch (category) {
          case 'Channel with ads cabinet':
            return {
              value: valueWithAbs,
              setValue: setValueWithAbs,
            };
          case 'SMM':
            return {
              value: valueSmm,
              setValue: setValueSmm,
            };
          case 'Other channels':
            return {
              value: valueOther,
              setValue: setValueOther,
            };
          default:
            return {
              value: '',
              setValue: (v: string) => {
                console.log(v);
              },
            };
        }
      };

      const selectors: {
        title: string;
        value: string;
        setValue: (v: string) => void;
        items: IChannelItem[];
      }[] = [];
      channels.forEach((ch) => {
        selectors.push({
          title: ch.category,
          value: mapper(ch.category).value,
          setValue: mapper(ch.category).setValue,
          items: ch.items,
        });
      });

      if (width <= mdLineWidth) {
        return (
          <Grid
            container
            className={cn('dropdown-wrapper', {
              cabinet: isCabinet,
            })}
          >
            <Grid item xs={12} className='dropdown'>
              <h4>Choose channel</h4>
              {selectors.map((selector, index) => (
                <FormControl className='form-control' key={index}>
                  <Select
                    displayEmpty
                    className='utm-select'
                    value={selector.value}
                    onChange={(e) => selector.setValue(e.target.value)}
                    sx={selectStyle}
                    inputProps={{ 'aria-label': 'Without label' }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span>{selector.title}</span>;
                      }
                      return selected;
                    }}
                  >
                    <ListSubheader className='group-title'>{selector.title}:</ListSubheader>
                    {selector.items.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </Grid>
          </Grid>
        );
      }

      return (
        <Grid
          container
          className={cn('dropdown-wrapper', {
            cabinet: isCabinet,
          })}
        >
          {selectors.map((selector, index) => (
            <Grid key={index} item xs={4}>
              <FormControl className='form-control'>
                <Select
                  displayEmpty
                  className='utm-select'
                  value={selector.value}
                  onChange={(e) => {
                    selector.setValue(e.target.value);
                  }}
                  sx={selectStyle}
                  inputProps={{ 'aria-label': 'Without label' }}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span>{selector.title}</span>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value=''>
                    <span>{selector.title}</span>
                  </MenuItem>
                  {selector.items.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      );
    },
  ),
);
