import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Grid } from '@mui/material';

import './utm-generator-main-form.scss';
import styles from './utm-generator-cabinet-form.module.scss';
import { UtmInput } from '../utm-input';
import { FullUrlInput } from '../full-url-input';
import { hoc } from '../../hoc';
import { IUtmGeneratorHook, useUtmGeneratorHook } from './use-utm-generator.hook';
import { UtmInputV2 } from '../utm-input/utm-input-v2';
import IChannel from '../../api/services/content/interfaces/channel.interface';
import { Dropdown } from 'components/dropdown';
import SelectedListItem from 'pages/cabinet/label-designer/components/list';

type InputProps = {
  url: string;
  setSelectedUrl: Dispatch<SetStateAction<string>>;
  channels: IChannel[] | undefined;
  landingsList: any;
};

export const UtmGeneratorCabinetFormComponent = hoc<InputProps, IUtmGeneratorHook>(
  useUtmGeneratorHook,
  ({
    utmAttributes,
    setUtmAttributes,
    editAttributes,
    link,
    setSource,
    setMedium,
    setContent,
    setCampaing,
    setTerm,
    setProtocol,
    setBaseUrl,
    canEditSource,
    canEditCampaing,
    canEditMedium,
    canEditContent,
    canEditTerm,
    clearAllAttributes,
    setAllAsEdit,
    url,
    setSelectedUrl,
    channels,
    landingsList,
  }) => {
    useEffect(() => {
      setBaseUrl(url);
    }, [url]);

    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Dropdown
            isCabinet
            channels={channels || []}
            canEditSource={canEditSource}
            canEditCampaing={canEditCampaing}
            canEditMedium={canEditMedium}
            canEditContent={canEditContent}
            canEditTerm={canEditTerm}
            setSource={setSource}
            setMedium={setMedium}
            setContent={setContent}
            setCampaing={setCampaing}
            setTerm={setTerm}
            setAllAsEdit={setAllAsEdit}
            clearAllAttributes={clearAllAttributes}
          />
          <h2 className={styles.title}>Оберіть рекламну сторінку</h2>
          <div className={styles.sites}>
            <SelectedListItem
              utmAttributes={utmAttributes}
              setUtmAttributes={setUtmAttributes}
              setSelectedUrl={setSelectedUrl}
              items={landingsList}
            />
          </div>
          <Grid container maxWidth='950px' className='utm-form-wrapper' spacing={1}>
            <Grid item md={6} xs={12} className='utm-form-element-row'>
              <UtmInputV2
                textFieldHandler={setSource}
                value={utmAttributes.utmSource}
                name='utm_source'
                canEdit={editAttributes.utmSource}
                canEditHandler={canEditSource}
                helpInfo='Help'
                placeholder='Google, Facebook, Tik-Tok...'
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className='utm-form-element-row'>
              <UtmInputV2
                textFieldHandler={setMedium}
                value={utmAttributes.utmMedium}
                name='utm_medium'
                canEdit={editAttributes.utmMedium}
                canEditHandler={canEditMedium}
                helpInfo='Help'
                placeholder='CPC, email, banner'
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className='utm-form-element-row'>
              <UtmInputV2
                textFieldHandler={setCampaing}
                value={utmAttributes.utmCampaing}
                name='utm_campaing'
                canEdit={editAttributes.utmCampaing}
                canEditHandler={canEditCampaing}
                helpInfo='Help'
                placeholder='campaign name'
                required={true}
              />
            </Grid>
            <Grid item md={6} xs={12} className='utm-form-element-row'>
              <UtmInputV2
                textFieldHandler={setContent}
                value={utmAttributes.utmContent}
                name='utm_content'
                canEdit={editAttributes.utmContent}
                canEditHandler={canEditContent}
                helpInfo='Help'
                placeholder='link, landing page'
              />
            </Grid>
            <Grid item md={6} xs={12} className='utm-form-element-row'>
              <UtmInputV2
                textFieldHandler={setTerm}
                value={utmAttributes.utmTerm}
                name='utm_term'
                canEdit={editAttributes.utmTerm}
                canEditHandler={canEditTerm}
                helpInfo='Help'
                placeholder='key word'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='utm-form-element-row utm-form-wrapper fullWidth'>
          <FullUrlInput isCabinet value={link} />
        </Grid>
      </Grid>
    );
  },
);
