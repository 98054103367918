import { ChannelDto } from './dto/channel.dto';
import Api from '../../api';
import { routes } from '../../routes';
import { IChannel } from '../../../modules/project/interfaces/channel';
import { channelDtoToEntity } from './mapper';
// import { mock as data} from './mock';

export default class ChannelService {
  static async getAvailableChannels(lang: 'UKR' | 'ENG' = 'ENG'): Promise<IChannel[]> {
    try {
      const data = await Api.get<ChannelDto[]>(routes.AVAILABLE_CHANNELS, { lang });
      return data.map(channelDtoToEntity).filter((ch) => !!ch) as IChannel[];
    } catch (error) {
      throw new Error('Error getting data. Please refresh the page.');
    }
  }
}
