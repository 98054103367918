import styles from './selectChannelBtn.module.scss';
import cn from 'classnames';

export const SelectChannelBtn = ({ btnData, activeChannelBtnLabel }: any): JSX.Element => {
  return (
    <button
      className={cn(styles.select, {
        [styles.active]: activeChannelBtnLabel == btnData.title,
      })}
    >
      {btnData.title}
    </button>
  );
};
