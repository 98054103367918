import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Pages } from 'containers/routers';

import { ReactComponent as AlbumsIcon } from 'assets/img/cabinet/albums.svg';
import { ReactComponent as ConstructIcon } from 'assets/img/cabinet/construct.svg';
import { ReactComponent as EaselIcon } from 'assets/img/cabinet/easel.svg';
import { ReactComponent as ExtensionPuzzleIcon } from 'assets/img/cabinet/extension-puzzle.svg';
import { ReactComponent as MegaphoneIcon } from 'assets/img/cabinet/megaphone.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/cabinet/settings.svg';

import styles from './styles.module.scss';

const items = [
  {
    title: 'Конструктор міток',
    icon: <ConstructIcon/>,
    path: Pages.cabinet,
  },
  {
    title: 'Рекламні канали',
    icon: <MegaphoneIcon/>,
    path: Pages.cabinetAdvertisingChannels,
  },
  {
    title: 'Продукти',
    icon: <ExtensionPuzzleIcon/>,
    path: Pages.cabinetProducts,
  },
  {
    title: 'Креативи',
    icon: <EaselIcon/>,
    path: Pages.cabinetCreatives,
  },
  {
    title: 'Проекти',
    icon: <AlbumsIcon/>,
    path: Pages.cabinetProjects,
  },
  {
    title: 'Налаштування',
    icon: <SettingsIcon/>,
    path: Pages.cabinetSettings,
  },
];

export const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <List classes={{
      root: styles.list
    }}>
      {items.map(({ title, icon, path }) => (
        <ListItem key={title} disablePadding>
          <ListItemButton selected={!!matchPath(path, location.pathname)}
                          onClick={() => (navigate(path))}
                          classes={{
                            root: styles.item,
                            selected: styles.itemSelected
                          }}>
            <ListItemIcon classes={{ root: styles.itemIcon }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={title}/>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
