import React from 'react';
import { TextField } from '@mui/material';
import styles from './styles.module.scss';

type InputProps = {
  id: string;
  name: string;
  placeholder: string;
  onChange?: (v: any) => any;
};

export const InputTable = ({ id, placeholder, name, onChange }: InputProps) => {
  return <TextField className={styles.input} id={id} name={name} onChange={onChange} variant="outlined" placeholder={placeholder}/>;
};
