import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { detectLanguage } from 'utils/languageUtil';

export const LanguageContext = React.createContext('en');

type InputProps = {
  children: ReactNode;
};

export const HomeLayout = ({ children }: InputProps) => {
  const location = useLocation();
  const language = detectLanguage(location.pathname);

  return <LanguageContext.Provider value={language}>{children}</LanguageContext.Provider>;
};
