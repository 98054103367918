import { Box, IconButton, Modal, Typography } from '@mui/material';

import crossIcon from 'assets/img/cross.svg';
import styles from './styles.module.scss';
import ellipse from '../../../assets/img/ellipses/ellipse-3.svg';
import React from 'react';

type InputProps = {
  ellipseTop?: boolean;
  open: boolean
  onClose: () => void;
  children: JSX.Element;
};

const InfoModal = ({
  ellipseTop,
  open,
  onClose,
  children
}: InputProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.root}>
        {!!ellipseTop && <img src={ellipse} className={styles.ellipseTop}/>}
        <div id="modal-modal-title" className={styles.close}>
          <IconButton onClick={onClose}><img src={crossIcon} alt="x"/></IconButton>
        </div>
        <div id="modal-modal-description" className={styles.content}>
          {children}
        </div>
        <img src={ellipse} className={styles.ellipseBottom}/>
      </Box>
    </Modal>
  );
};

export default InfoModal;
