import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { transliterate as tr } from 'transliteration';

interface UtmAttributesInterface {
  utmSource: string;
  utmCampaing: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
  utmProtocol: string;
  utmBaseUrl: string;
}

interface CanEditFieldInterface {
  utmSource: boolean;
  utmCampaing: boolean;
  utmMedium: boolean;
  utmContent: boolean;
  utmTerm: boolean;
}

interface QueryInterface {
  utm_source: string;
  utm_medium: string;
  utm_campaing: string;
  utm_content?: string;
  utm_term?: string;
}

const encodeQueryData = (data: { [key: string]: string }): string => {
  const ret = [];
  for (const d in data) data[d] && ret.push(d + '=' + data[d]);
  return '?' + ret.join('&');
};

export interface IUtmGeneratorHook {
  setSource: (source: string) => void;
  setContent: (content: string) => void;
  canEditContent: (value: boolean) => void;
  canEditSource: (value: boolean) => void;
  canEditMedium: (value: boolean) => void;
  setEditAttributes: Dispatch<SetStateAction<CanEditFieldInterface>>;
  setCampaing: (value: string) => void;
  setMedium: (value: string) => void;
  setTerm: (value: string) => void;
  setBaseUrl: (value: string) => void;
  canEditTerm: (value: boolean) => void;
  utmAttributes: UtmAttributesInterface;
  setUtmAttributes: Dispatch<SetStateAction<UtmAttributesInterface>>;
  setProtocol: (protocol: string) => void;
  link: string;
  editAttributes: CanEditFieldInterface;
  canEditCampaing: (value: boolean) => void;
  clearAllAttributes: () => void;
  setAllAsEdit: () => void;
}

export const useUtmGeneratorHook = (): IUtmGeneratorHook => {
  const [utmAttributes, setUtmAttributes] = useState<UtmAttributesInterface>({
    utmSource: '',
    utmCampaing: '',
    utmMedium: '',
    utmContent: '',
    utmTerm: '',
    utmProtocol: 'https://',
    utmBaseUrl: '',
  });

  const [editAttributes, setEditAttributes] = useState<CanEditFieldInterface>({
    utmSource: true,
    utmCampaing: true,
    utmMedium: true,
    utmContent: true,
    utmTerm: true,
  });

  const [link, setLink] = useState<string>('');

  useEffect(() => {
    if (
      // utmAttributes.utmTerm &&
      // utmAttributes.utmContent &&
      utmAttributes.utmCampaing &&
      utmAttributes.utmMedium &&
      utmAttributes.utmSource &&
      utmAttributes.utmBaseUrl &&
      utmAttributes.utmProtocol
    ) {
      const link =
        utmAttributes.utmProtocol +
        utmAttributes.utmBaseUrl +
        encodeQueryData({
          utm_source: utmAttributes.utmSource,
          utm_medium: utmAttributes.utmMedium,
          utm_campaing: utmAttributes.utmCampaing,
          utm_content: utmAttributes.utmContent,
          utm_term: utmAttributes.utmTerm,
        });

      return setLink(tr(link));
    }

    setLink('');
  }, [utmAttributes]);

  const clearAllAttributes = () =>
    setUtmAttributes((prevState) => ({
      ...prevState,
      utmSource: '',
      utmMedium: '',
      utmCampaing: '',
      utmContent: '',
      utmTerm: '',
    }));

  const setAllAsEdit = () =>
    setEditAttributes((prevState) => ({
      ...prevState,
      utmSource: true,
      utmMedium: true,
      utmCampaing: true,
      utmContent: true,
      utmTerm: true,
    }));

  const setSource = (source: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmSource: source }));

  const canEditSource = (value: boolean) =>
    setEditAttributes((prevState) => ({ ...prevState, utmSource: value }));

  const setCampaing = (campaing: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmCampaing: campaing }));

  const canEditCampaing = (value: boolean) =>
    setEditAttributes((prevState) => ({ ...prevState, utmCampaing: value }));

  const setMedium = (medium: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmMedium: medium }));

  const canEditMedium = (value: boolean) =>
    setEditAttributes((prevState) => ({ ...prevState, utmMedium: value }));

  const setContent = (content: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmContent: content }));

  const canEditContent = (value: boolean) =>
    setEditAttributes((prevState) => ({ ...prevState, utmContent: value }));

  const setTerm = (term: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmTerm: term }));

  const canEditTerm = (value: boolean) => {
    setEditAttributes((prevState) => ({ ...prevState, utmTerm: value }));
  };

  const setProtocol = (protocol: string) =>
    setUtmAttributes((prevState) => ({ ...prevState, utmProtocol: protocol }));

  const setBaseUrl = (baseUrl: string) => {
    setUtmAttributes((prevState) => ({ ...prevState, utmBaseUrl: baseUrl }));
  };

  return {
    utmAttributes,
    setUtmAttributes,
    editAttributes,
    setEditAttributes,
    link,
    setSource,
    setCampaing,
    setMedium,
    setContent,
    setTerm,
    setProtocol,
    setBaseUrl,
    canEditSource,
    canEditCampaing,
    canEditMedium,
    canEditContent,
    canEditTerm,
    clearAllAttributes,
    setAllAsEdit,
  };
};
