import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@mui/material';

import SkipButton from 'components/buttons/skip-button';

import styles from './styles.module.scss';
import { AddButton } from 'components/buttons/add-button';
import { SelectTable } from 'components/selects/select-table';
import { IStepProps } from '../istep.props';
import ConfirmModal from '../../confirm-modal';
import { FieldArray, Form, Formik } from 'formik';
import SubmitButton from '../../../../../components/buttons/main-button/submit';
import { PreviewUpload } from '../../../../../components/preview-upload/preview-upload';
import clsx from 'clsx';
import { BackBtn } from '../../backBtn/backBtn';

interface IValue {
  preview: string;
  channel: string;
  type: 'video' | 'banner';
  index: string;
}

const emptyData = {
  preview: '',
  channel: '',
  type: '',
  index: '0',
};

const types = [
  {
    label: 'video',
    value: 'video',
  },
  {
    label: 'banner',
    value: 'banner',
  },
];

export const AdsManagementSystem = ({
  onStepFinish,
  onGoBackStep,
  onGoToNextStep,
  onSkip,
  project,
}: IStepProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<{ [key: string]: any }>({});
  const matches500 = useMediaQuery('(max-width:500px)');

  const channels = useMemo(() => {
    if (!project?.channels.length) {
      return [];
    }
    return project?.channels.map((c) => ({
      label: c.title,
      value: c.id.toString(),
    }));
  }, [JSON.stringify(project?.channels)]);

  return (
    <div className={styles.root}>
      <div className={styles.root__container}>
        <BackBtn onGoBackStep={onGoBackStep} />
        <h2>Step 4/5. Ads management system</h2>

        <div className={styles.content}>
          <Formik
            initialValues={{
              ads: [emptyData],
            }}
            onSubmit={(values) => {
              const channels = project?.channels.map((channel) => {
                values.ads.map((el, index) => (el.index = index.toString()));
                const adsManagementSystems = values.ads
                  .filter((v) => v.channel === channel.id.toString() && v.type)
                  .map((v) => {
                    return {
                      type: v.type,
                      preview: files[v.index] || null,
                    };
                  });

                return {
                  ...channel,
                  adsManagementSystems,
                };
              });
              onStepFinish(channels);
              setOpen(true);
            }}
            render={({ values }) => (
              <Form className={styles.form}>
                <FieldArray
                  name='ads'
                  render={(arrayHelpers) => (
                    <div className={styles.tableWrapper}>
                      <Table aria-label='simple table' className={styles.table}>
                        <TableHead className={styles.header}>
                          <TableRow>
                            <TableCell>Preview</TableCell>
                            {!matches500 && <TableCell>Channel</TableCell>}
                            {!matches500 && <TableCell>Type</TableCell>}
                            {matches500 && <TableCell>Channel/Type</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody className={styles.body}>
                          {values.ads.map((el, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className={styles.previewWrapper}>
                                  <PreviewUpload
                                    onChange={(file) => {
                                      setFiles((prevState) => {
                                        return {
                                          ...prevState,
                                          [index.toString()]: file,
                                        };
                                      });
                                    }}
                                  />
                                  <input
                                    type='hidden'
                                    name={`ads.${index}.channel`}
                                    value={index.toString()}
                                  />
                                </TableCell>
                                <TableCell className={clsx(styles.paramsCell)}>
                                  <SelectTable
                                    value={values.ads[index].channel}
                                    name={`ads.${index}.channel`}
                                    onChange={arrayHelpers.form.handleChange}
                                    options={channels}
                                    label='Choose channel'
                                    className={clsx(styles.select, styles.channel)}
                                  />
                                  {matches500 && (
                                    <SelectTable
                                      value={values.ads[index].type}
                                      name={`ads.${index}.type`}
                                      onChange={arrayHelpers.form.handleChange}
                                      options={types}
                                      label='Choose type'
                                      className={clsx(styles.select, styles.type)}
                                    />
                                  )}
                                </TableCell>
                                {!matches500 && (
                                  <TableCell>
                                    <SelectTable
                                      value={values.ads[index].type}
                                      name={`ads.${index}.type`}
                                      onChange={arrayHelpers.form.handleChange}
                                      options={types}
                                      label='Choose type'
                                      className={clsx(styles.select, styles.type)}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <div className={styles.add}>
                        <AddButton
                          onClick={() => {
                            arrayHelpers.push(emptyData);
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
                <div className={styles.actions}>
                  <SkipButton onClick={onSkip} />
                  <SubmitButton>Ready</SubmitButton>
                </div>
              </Form>
            )}
          />
        </div>

        <ConfirmModal
          open={open}
          onClose={() => setOpen(false)}
          onNext={() => {
            setOpen(false);
            onGoToNextStep();
          }}
          title='Add additional parameters of traffic channels'
          text='This will help you organize your work better.'
        />
      </div>
    </div>
  );
};
