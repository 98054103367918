import React, { FC, useId } from 'react';
import { Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './main-slider.scss';
import { UseMainSliderHooks } from './main-slider.hooks';
import { hoc, windowSize, WindowSizeProps } from '../../hoc';
import {
  ControlButtonProps,
  SetSelectItem,
  SliderHookProps,
  SliderIndicatorProps,
  SliderItemProps,
  SlideRow,
} from './main-slider.props';
import sliderData from '../../assets/data/slider.data.json';
import { mdLineWidth } from '../../common/constants';
import IParameter, { IParameterItem } from '../../api/services/content/interfaces/parametr.interface';
import { ApiConfig } from '../../api/config';

const SliderIndicator: FC<SliderIndicatorProps> = ({ selectItem }) => (
  <div className='indicator-wrapper'>
    {sliderData.map((_, index) => (
      <div key={index} className={index === selectItem ? 'active-dot' : 'dot'}></div>
    ))}
  </div>
);

const ControlButton: FC<ControlButtonProps> = ({
  setSelectItem,
  item,
  title,
  selectItem,
  logo,
}) => {
  return (
    <div
      className={`control-btn ${item === selectItem ? 'active-control-btn' : ''}`}
      onClick={() => setSelectItem(item)}
    >
      {logo ? (
        <div className={'control-btn-img-wrapper'}>
          <img src={`${ApiConfig.API_URL}${logo}`} alt='' />
        </div>
      ) : (
        <div className={'control-btn-img-wrapper'}></div>
      )}
      {title}
    </div>
  );
};

const renderSlideRow = (sliderRows: IParameterItem[]) => {
  return sliderRows.map((item) => (
    <Grid className='slide-row' container key={useId()}>
      <Grid item xl={2} sm={3} xs={12}>
        <div className='filed-name'>{item.name}</div>
      </Grid>
      <Grid item xl={10} sm={9} xs={12}>
        <div className='filed-comment'>{item.description}</div>
      </Grid>
    </Grid>
  ));
};

const Item: FC<SliderItemProps> = ({ slideRows }) => {
  return <div className='main-slider-item'>{renderSlideRow(slideRows)}</div>;
};

const renderControlButton = (parameters: IParameter[], setSelectItem: SetSelectItem, selectItem: number) => {
  return parameters.map((parameter, index) => (
    <ControlButton
      key={useId()}
      setSelectItem={setSelectItem}
      item={index}
      title={parameter.category || ''}
      logo={parameter.icon || undefined}
      selectItem={selectItem}
    />
  ));
};

const renderSliderItems = (parameters: IParameter[]) => {
  return parameters.map((parameter) => <Item key={useId()} slideRows={parameter.items} />);
};
type InputProps = {
  parameters: IParameter[];
};

export const MainSlider = windowSize<InputProps>(
  hoc<WindowSizeProps & InputProps, SliderHookProps>(UseMainSliderHooks, ({ setSelectItem, selectItem, width, parameters }) => {
    return (
      <div className='main-slider-wrapper'>
        <div className='main-slider-title'>Main parameters</div>
        {width <= mdLineWidth ? <SliderIndicator selectItem={selectItem} /> : null}
        <Grid container className='main-slider-content'>
          <Grid item xl={3} md={4} xs={12} className='control-button-wrapper'>
            {renderControlButton(parameters, setSelectItem, selectItem)}
          </Grid>
          <Grid item xl={9} md={8} xs={12}>
            <Carousel
              className='main-slider'
              selectedItem={selectItem}
              swipeable={false}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
            >
              {renderSliderItems(parameters)}
            </Carousel>
          </Grid>
        </Grid>
      </div>
    );
  }),
);
