import clsx from 'clsx';
import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { Field, FieldArray } from 'formik';
import cn from 'classnames';
import { AddButton } from '../../buttons/add-button';
import { ReactComponent as ArrowIcon } from 'assets/img/arrow-params.svg';
import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';
import styles from './styles.module.scss';
import { IChannelAdditionalParameter } from '../../../modules/project/interfaces/channel-additional-parameter';

type InputProps = {
  field: string;
  title: string;
  icon: string;
  channelProps: IChannelAdditionalParameter;
  values?: any;
  defaultHide?: boolean;
  isCabinet?: boolean;
};

type InputPropsParam = {
  values?: any;
  defaultHide?: boolean;
  arrayHelpers?: any;
  title: string;
  field: string;
  isCabinet?: boolean;
};

export const ChannelParamV2 = ({
  values,
  arrayHelpers,
  title,
  field,
  defaultHide,
  isCabinet,
}: InputPropsParam) => {
  const [hide, setHide] = useState(defaultHide);

  const toggleHandler = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <div className={clsx(styles.rootParams, isCabinet && styles.cabinet)}>
      <div className={clsx(styles.header, hide && styles.hide)}>
        <IconButton
          className={clsx(styles.arrowBtn, hide && styles.arrowBtnHide)}
          onClick={toggleHandler}
        >
          <ArrowIcon />
        </IconButton>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={clsx(hide && styles.hide)}>
        {values.map((item: any, index: any) => (
          <div
            key={index}
            className={cn(styles.formRow, {
              [styles.cabinet]: isCabinet,
            })}
          >
            <Field
              className={isCabinet && styles.cabinet_input}
              name={`${field}.${index}.value`}
              placeholder={`${title}_${index + 1}`}
            />
            <IconButton
              className={clsx(styles.arrowBtn, hide && styles.arrowBtnHide)}
              onClick={() => {
                arrayHelpers.remove(index);
              }}
            >
              <TrashIcon />
            </IconButton>
          </div>
        ))}
        <div className={styles.actions}>
          <AddButton
            onClick={() => {
              arrayHelpers.push({
                value: '',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const ChannelParamsV2 = ({
  field,
  channelProps,
  title,
  icon,
  values,
  defaultHide,
  isCabinet,
}: InputProps) => {
  const [hide, setHide] = useState(defaultHide);

  const toggleHandler = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <div className={clsx(styles.root, hide && styles.hide, isCabinet && styles.cabinet)}>
      {!isCabinet && (
        <div className={styles.header}>
          <IconButton
            className={clsx(styles.arrowBtn, hide && styles.arrowBtnHide)}
            onClick={toggleHandler}
          >
            <ArrowIcon />
          </IconButton>
          {icon && <div className={styles.icon}>{<img src={icon} />}</div>}
          <div className={styles.title}>{title}</div>
        </div>
      )}

      <div className={clsx(hide && styles.hide)}>
        {channelProps &&
          Object.keys(channelProps).map((p, index) => (
            <FieldArray
              key={p}
              name={`${field}#${p}`}
              render={(arrayHelpers) => (
                <ChannelParamV2
                  key={p}
                  title={p}
                  arrayHelpers={arrayHelpers}
                  values={values[`${field}#${p}`]}
                  field={`${field}#${p}`}
                  defaultHide={!!index}
                  isCabinet={isCabinet}
                />
              )}
            />
          ))}
      </div>
    </div>
  );
};
