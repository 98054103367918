import { Button } from '@mui/material';

import styles from './styles.module.scss';

type InputProps = {
  disabled?: boolean;
  onClick: () => void;
};

const SkipButton = ({ disabled, onClick }: InputProps) => {
  return (
    <Button
      disabled={disabled}
      variant="text"
      className={styles.btn}
      onClick={onClick}
    >Skip</Button>
  );
};

export default SkipButton;
