import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import MainLayout from 'components/MainLayout';
import LoginForm from 'components/login/login-form';
import CreateProjectForm from 'components/create-project/create-project-form';

import styles from './styles.module.scss';
import ProjectCreatedModal from './components/project-created-modal';
import { ChooseChannel } from './components/steps/choose-channel';
import { ChooseResponsible } from './components/steps/choose-responsible';
import { FillLandingProduct } from './components/steps/fill-landing-product';
import { AdsManagementSystem } from './components/steps/ads-management-system';
import { AddAdditionalParameters } from './components/steps/add-additional-parameters';
import { IProject } from '../../modules/project/interfaces/project';
import { emptyProject } from '../../modules/project/constants/empty-project';
import { ChannelType, IChannel } from '../../modules/project/interfaces/channel';
import { ILandingPage } from '../../modules/project/interfaces/landing-page';
import { Pages } from '../../containers/routers';
import { CreateProjectRobot } from '../../components/robots/create-project-robot';
import ChannelService from '../../api/services/channel/channel.service';
import ProjectService from '../../api/services/project/project.service';
import { TUser } from 'react-telegram-auth';
import AuthService from '../../api/services/auth/auth.service';
import { LoginTelegramResponseDto } from '../../api/services/auth/dto/login-telegram-response.dto';

type Step = 0 | 1 | 2 | 3 | 4 | 5;

const LoginPage = () => {
  const navigate = useNavigate();
  // TODO: refactor isLogin
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [project, setProject] = useState<IProject>(emptyProject);
  const [step, setStep] = useState<Step>(0);
  const snackbar = useSnackbar();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lang = params.get('lang') ? 'UKR' : 'ENG';
  const [channels, setChannels] = useState<IChannel[]>([]);

  const matches500 = useMediaQuery('(max-width:500px)');

  const goToCabinet = () => {
    navigate(Pages.cabinet);
  };

  useEffect(() => {
    ChannelService.getAvailableChannels(lang)
      .then((channels) => {
        setChannels(channels);
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [lang, snackbar]);

  const onCreateHandler = (name: string) => {
    setProject((prevState) => ({
      ...prevState,
      name,
    }));
    setOpenModal(true);
  };

  const onSelectedChannelsHandler = (channels: IChannel[]) => {
    setProject((prevState) => ({
      ...prevState,
      channels: channels.map((c) => ({
        ...c,
        responsiblePersons: [],
        adsManagementSystems: [],
      })),
    }));
  };

  const onSelectedResponsiblePersonsHandler = (channels: IChannel[]) => {
    setProject((prevState) => ({
      ...prevState,
      channels: channels,
    }));
  };

  const onSelectedLandingPagesHandler = (landingPages: ILandingPage[]) => {
    setProject((prevState) => ({
      ...prevState,
      landingPages,
    }));
  };

  const onSelectedAdsManagementSystemsHandler = (channels: IChannel[]) => {
    setProject((prevState) => ({
      ...prevState,
      channels: channels,
    }));
  };

  const onSelectedAdditionalParametersHandler = (channels: IChannel[]) => {
    setProject((prevState) => ({
      ...prevState,
      channels: channels,
    }));
  };

  const onSkipHandler = () => {
    goToCabinet();
  };

  const onSaveHandler = () => {
    ProjectService.createProject(project)
      .then(() => {
        snackbar.enqueueSnackbar('Saved!', { variant: 'success' });
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const onAuthHandle = (user: TUser) => {
    AuthService.login(user)
      .then((res: LoginTelegramResponseDto) => {
        console.log(res.tokens.access_token);
        setIsLogin(true);
        snackbar.enqueueSnackbar('Saved!', { variant: 'success' });
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <MainLayout>
      <>
        {!step && (
          <div className={styles.root}>
            <LoginForm onAuthCallback={onAuthHandle} />
            <CreateProjectForm
              onSkip={onSkipHandler}
              onCreate={onCreateHandler}
              disabled={!isLogin}
            />
            {matches500 ? (
              <Box className={styles.robotMob} alignSelf='flex-end'>
                <CreateProjectRobot title='Let’s create perfect links!' />
              </Box>
            ) : (
              <Box paddingBottom='67px' alignSelf='flex-end'>
                <CreateProjectRobot title='Let’s create perfect links!' />
              </Box>
            )}
          </div>
        )}

        {step === 1 && (
          <ChooseChannel
            onSkip={onSkipHandler}
            onGoBackStep={() => setStep(0)}
            onGoToNextStep={() => setStep(2)}
            onStepFinish={onSelectedChannelsHandler}
            adsCabinetChannels={channels.filter((ch) => ch.type === ChannelType.ADS_CABINET)}
            smmChannels={channels.filter((ch) => ch.type === ChannelType.SMM)}
            otherChannels={channels.filter((ch) => ch.type === ChannelType.OTHER)}
          />
        )}
        {step === 2 && (
          <ChooseResponsible
            onSkip={onSkipHandler}
            onGoBackStep={() => setStep(1)}
            onGoToNextStep={() => setStep(3)}
            onStepFinish={onSelectedResponsiblePersonsHandler}
            project={project}
          />
        )}
        {step === 3 && (
          <FillLandingProduct
            onSkip={onSkipHandler}
            onGoBackStep={() => setStep(2)}
            onGoToNextStep={() => setStep(4)}
            onStepFinish={onSelectedLandingPagesHandler}
          />
        )}
        {step === 4 && (
          <AdsManagementSystem
            onSkip={onSkipHandler}
            onGoBackStep={() => setStep(3)}
            onGoToNextStep={() => setStep(5)}
            onStepFinish={onSelectedAdsManagementSystemsHandler}
            project={project}
          />
        )}
        {step === 5 && (
          <AddAdditionalParameters
            onSkip={onSkipHandler}
            onGoBackStep={() => setStep(4)}
            onGoToNextStep={onSaveHandler}
            onStepFinish={onSelectedAdditionalParametersHandler}
            project={project}
          />
        )}

        <ProjectCreatedModal
          projectName={project.name}
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onNext={() => {
            setOpenModal(false);
            setStep(1);
          }}
        />
      </>
    </MainLayout>
  );
};

export default LoginPage;
