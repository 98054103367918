import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import styles from './styles.module.scss';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type ListItem = {
  id: number;
  protocol: string;
  url: string;
  productName: string;
};

type InputProps = {
  items: ListItem[];
  setSelectedUrl: Dispatch<SetStateAction<string>>;
  setUtmAttributes: Dispatch<SetStateAction<any>>;
  utmAttributes: any;
};

export default function SelectedListItem({
  items,
  setSelectedUrl,
  utmAttributes,
  setUtmAttributes,
}: InputProps) {
  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    if (items) {
      setSelectedIndex(items[0].id);
      setSelectedUrl(items[0].url);
      setUtmAttributes({ ...utmAttributes, utmProtocol: `${items[0].protocol}://` });
    }
  }, [items]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List component='nav' aria-label='secondary mailbox folder'>
      {items?.map((item) => (
        <ListItemButton
          className={clsx(styles.root)}
          key={item.id}
          selected={selectedIndex === item.id}
          onClick={(event) => {
            handleListItemClick(event, item.id);
            setSelectedUrl(item.url);
            setUtmAttributes({ ...utmAttributes, utmProtocol: `${item.protocol}://` });
          }}
          classes={{
            selected: styles.selected,
          }}
        >
          <div>{item.url}</div>
          <div>{item.productName}</div>
        </ListItemButton>
      ))}
    </List>
  );
}
