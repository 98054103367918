import * as React from 'react';
import styles from './styles.module.scss';
import { AppConfig } from '../../config';

const FooterTop = () => {
  return (
    <div className={styles.footerTop}>
      <img
        className={styles.footerTop__logo}
        src={`${AppConfig.IMAGE_URL}/blog/img/profit_store_logo.png`}
        alt='Profit Store Logo'
      />
      <div className={styles.footerTop__socials}>
        <a
          className={styles.footerTop__socialsLink}
          href='https://www.instagram.com/bi.profit.store/'
        >
          <img
            className={styles.footerTop__socialsImage}
            src={`${AppConfig.IMAGE_URL}/blog/img/footer/instagram_icon.svg`}
            alt='Instagram Icon'
          />
        </a>
        <a
          className={styles.footerTop__socialsLink}
          href='https://www.youtube.com/@profitstore5495'
        >
          <img
            className={styles.footerTop__socialsImage}
            src={`https://profit.store/api/uploads/Youtube3_344615fa30.svg`}
            alt='Twitter Icon'
          />
        </a>
        <a
          className={styles.footerTop__socialsLink}
          href='https://www.facebook.com/IT.Profit.Store/'
        >
          <img
            className={styles.footerTop__socialsImage}
            src={`${AppConfig.IMAGE_URL}/blog/img/footer/facebook_icon.svg`}
            alt='Facebook Icon'
          />
        </a>
        <a
          className={styles.footerTop__socialsLink}
          href='https://www.linkedin.com/company/profit-store/?originalSubdomain=uk'
        >
          <img
            className={styles.footerTop__socialsImage}
            src={`${AppConfig.IMAGE_URL}/blog/img/footer/linkedin_icon.svg`}
            alt='LinkedIn Icon'
          />
        </a>
      </div>
    </div>
  );
};

export default FooterTop;
