import cx from 'classnames';
import styles from './dropdown.module.scss';
import { useLocation } from 'react-router-dom';
import { LanguagesEnum } from '../../../common/language.type';
import { useContext } from 'react';
import { LanguageContext } from 'layouts/HomeLayout';

type InputProps = {
  dropdown: any[];
}

const Dropdown = ({ dropdown }: InputProps) => {
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const location = useLocation();

  return (
    <div className={styles.dropdown}>
      {dropdown.map((list, index) => (
        <ul key={index} className={styles.dropdown__list} aria-label={list[language].title}>
          {list[language].li.map((item: any) => (
            <li key={item.title} className={styles.dropdown__item}>
              <a href={item.link}>
                <span
                  className={cx({
                    [styles.dropdown__link]: true,
                    [styles['dropdown__link--selected']]: location.pathname.includes(item.link)
                  })}
                >
                  {item.title}
                </span>
              </a>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default Dropdown;
