import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../useMediaQuery';
import useNoScrollBody from '../useNoScrollBody';
import translations from '../../../consts/translations';
import dropdown from '../../../consts/dropdown';
import styles from '../header.module.scss';
import dropdownStyles from '../Dropdown/dropdown.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { AppConfig } from '../../../config';
import { LanguagesEnum } from '../../../common/language.type';
import { LanguageContext } from 'layouts/HomeLayout';

type InputProps = {
  openMenu: boolean;
  openContactUsModal: boolean;
  setOpenContactUsModal: (v: boolean) => void;
};

const MobileMenu = ({ openMenu, openContactUsModal, setOpenContactUsModal }: InputProps) => {
  const location = useLocation();
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const [openProducts, setOpenProducts] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);
  const isTablet = useMediaQuery();
  useNoScrollBody(openMenu);

  return (
    <div
      className={cx({
        [styles.menu]: true,
        [styles['menu--open']]: openMenu,
      })}
    >
      <a
        href={language === LanguagesEnum.UK ? 'https://profit.store/uk' : 'https://profit.store'}
        className={styles.menu__link}
        style={{ marginLeft: 0, fontSize: '1rem', fontWeight: '400', marginBottom: '22px' }}
      >
        {language === LanguagesEnum.UK ? 'Головна' : 'Home'}
      </a>

      <button
        className={cx({
          [styles.menu__header]: true,
          [styles['menu__header--selected']]: openProducts,
        })}
        onClick={() => setOpenProducts(!openProducts)}
      >
        {language === LanguagesEnum.UK ? 'Продукти' : 'Products'}
        {!isTablet && (
          <img
            className={cx({
              [styles.menu__icon]: true,
              [styles['menu__icon--selected']]: openProducts,
            })}
            src={`${AppConfig.IMAGE_URL}/blog/img/arrow_down.svg`}
            alt='Open dropdown icon'
          />
        )}
      </button>
      {(isTablet || openProducts) && (
        <div className={dropdownStyles.dropdown} style={{ padding: 0 }}>
          {dropdown.products.map((list, index) => (
            <ul
              key={index}
              className={dropdownStyles.dropdown__list}
              aria-label={list[language].title}
            >
              {list[language].li.map((item) => (
                <li key={item.title} className={dropdownStyles.dropdown__item}>
                  <a href={item.link} className={dropdownStyles.dropdown__link}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          ))}
          <div className={styles.menu__line} />
        </div>
      )}

      <button
        className={cx({
          [styles.menu__header]: true,
          [styles['menu__header--selected']]: openProjects,
        })}
        onClick={() => setOpenProjects(!openProjects)}
      >
        {language === LanguagesEnum.UK ? 'Проекти' : 'Projects'}
        {!isTablet && (
          <img
            className={cx({
              [styles.menu__icon]: true,
              [styles['menu__icon--selected']]: openProjects,
            })}
            src={`${AppConfig.IMAGE_URL}/blog/img/arrow_down.svg`}
            alt='Open dropdown icon'
          />
        )}
      </button>
      {(isTablet || openProjects) && (
        <div className={dropdownStyles.dropdown} style={{ padding: 0 }}>
          {dropdown.projects.map((list, index) => (
            <ul
              key={index}
              className={dropdownStyles.dropdown__list}
              aria-label={list[language].title}
            >
              {list[language].li.map((item) => (
                <li key={item.title} className={dropdownStyles.dropdown__item}>
                  <a href={item.link} className={dropdownStyles.dropdown__link}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          ))}
          <div className={styles.menu__line} />
        </div>
      )}

      <a
        href={language === LanguagesEnum.UK ? 'https://profit.store/uk' : 'https://profit.store'}
        className={styles.menu__header}
      >
        {language === LanguagesEnum.UK ? 'Блог' : 'Blog'}
      </a>

      <div className={styles.menu__line} style={{ marginTop: '10px' }} />

      <h2
        className={styles.menu__header}
        style={{ fontWeight: 700, color: '#757575', marginBottom: '10px' }}
      >
        {language === LanguagesEnum.UK ? 'Мова' : 'Languages'}
      </h2>
      <div className={styles.menu__langs}>
        <Link
          to={`${
            window.location.href.endsWith('/')
              ? window.location.href.slice(0, -1)
              : window.location.href
          }/uk`}
          lang='uk'
        >
          <a
            className={cx({
              [styles.menu__langsButton]: true,
              [styles['menu__langsButton--selected']]: language === LanguagesEnum.UK,
            })}
          >
            {translations[language].footer.languageUK}
          </a>
        </Link>
        <span className={styles.menu__langsDivider}>/</span>
        <Link
          to={`${window.location.href.substring(0, window.location.href.lastIndexOf('/uk'))}`}
          lang='en'
        >
          <a
            className={cx({
              [styles.menu__langsButton]: true,
              [styles['menu__langsButton--selected']]: language === LanguagesEnum.EN,
            })}
          >
            {translations[language].footer.languageEN}
          </a>
        </Link>
      </div>
      {location.pathname !== '/login' ? (
        <Link to='/login' className={`${styles.menu__contactUsButton} ${styles.menu__login}`}>
          Log In
        </Link>
      ) : (
        <button
          type='button'
          className={styles.menu__contactUsButton}
          onClick={() => setOpenContactUsModal(!openContactUsModal)}
        >
          {translations[language].header.button}
        </button>
      )}
    </div>
  );
};

export default MobileMenu;
