import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter, ErrorBoundary } from './containers';

export function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AppRouter/>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

