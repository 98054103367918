import { ChannelDto } from './dto/channel.dto';
import { ChannelType, IChannel } from '../../../modules/project/interfaces/channel';

export const channelDtoToEntity = (dto: ChannelDto): IChannel | null => {
  if (!(dto.id && dto.title)) {
    return null;
  }

  let additionalParameters = {};

  if (dto.additionalParameters?.length) {
    additionalParameters = dto.additionalParameters.reduce(
      (hash: { [key: string]: string[] }, current: string) => {
        hash[current] = [];
        return hash;
      },
      {},
    );
  }

  return {
    id: dto.id,
    title: dto.title,
    icon: dto.icon || '',
    responsiblePersons: [],
    additionalParameters,
    adsManagementSystems: [],
    type: dto.type as ChannelType,
  };
};
