import { useContext, useState } from 'react';
import Navigation from './Navigation';
import MobileMenu from './MobileMenu';
import Languages from './Languages';
import translations from '../../consts/translations';
import styles from './header.module.scss';
import ContactUsModal from './ContactUsModal';
import { AppConfig } from '../../config';
import { LanguageContext } from 'layouts/HomeLayout';
import { LanguagesEnum } from '../../common/language.type';
import { Link, useLocation } from 'react-router-dom';
import { Pages } from 'containers/routers';

const Header = () => {
  const location = useLocation();
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <div className={styles.header__content}>
          <a href='https://profit.store'>
            <a id='logo' className={styles.header__logoLink}>
              <img
                className={styles.header__logo}
                src={`${AppConfig.IMAGE_URL}/blog/img/profit_store_logo.png`}
                alt='Profit Store Logo'
              />
            </a>
          </a>
          <div className={styles.header__actions}>
            <Navigation />
            <Languages />
            {location.pathname != '/utm-builder/login' ? (
              <Link to={Pages.login} className={`${styles.header__button} ${styles.header__login}`}>
                Log In
              </Link>
            ) : (
              <button
                type='button'
                className={styles.header__button}
                onClick={() => setOpenContactUsModal(!openContactUsModal)}
              >
                {translations[language].header.button}
              </button>
            )}
          </div>
          <ContactUsModal
            openContactUsModal={openContactUsModal}
            setOpenContactUsModal={setOpenContactUsModal}
          />
          <button className={styles.menu__button} onClick={() => setOpenMenu(!openMenu)}>
            {openMenu ? (
              <img
                className={styles.menu__icon}
                src={`${AppConfig.IMAGE_URL}/blog/img/close_icon.svg`}
                alt='Menu icon'
              />
            ) : (
              <img
                className={styles.menu__icon}
                src={`${AppConfig.IMAGE_URL}/blog/img/burger_icon.svg`}
                alt='Menu icon'
              />
            )}
          </button>
          <MobileMenu
            openMenu={openMenu}
            openContactUsModal={openContactUsModal}
            setOpenContactUsModal={setOpenContactUsModal}
          />
        </div>
        <div className={styles.header__line}></div>
      </div>
    </header>
  );
};

export default Header;
