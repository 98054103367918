import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';

type InputProps = {
  onChange: (e: any) => void;
};

export const PreviewUpload = ({ onChange }: InputProps, ref: any) => {
  const inputFile: any = useRef(null);
  const [file, setFile] = useState<any>();
  const [fileType, setFileType] = useState<string>();

  function handleChange(e: any) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setFileType(e.target.files[0]?.type || '');
    const data = new FileReader();
    data.addEventListener('load', () => {
      setFile(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  }

  useEffect(() => {
    if (file) {
      onChange(file);
    }
  }, [file]);

  const isVideoFile = useMemo(() => {
    // TODO: add types
    return fileType === 'video/mp4';
  }, [fileType]);

  const onClickHandler = useCallback(() => {
    if ('current' in ref) {
      ref.current.click();
      ref.current.value = '';
    } else {
      inputFile.current.click();
      inputFile.current.value = '';
    }
  }, [ref]);

  return (
    <div className={styles.root} onClick={onClickHandler}>
      {file && !isVideoFile && <img src={file} alt='' className={styles.uploadImg} />}
      {file && isVideoFile && (
        <video className={styles.uploadImg}>
          <source src={file} />
        </video>
      )}
      <input
        onChange={handleChange}
        ref={'current' in ref ? ref : inputFile}
        accept='image/png, image/jpg, image/jpeg, video/mp4'
        type='file'
      />
    </div>
  );
};
