import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { ReactComponent as selectArrow } from 'assets/img/select-arrow.svg'

type Option = {
  label: string;
  value: string;
};

type InputProps = {
  className?: string;
  value: string;
  name: string;
  label?: string;
  onChange?: (v: any) => any;
  options: Option[];
};

export const SelectTable = ({ className, name, label = '', value, onChange, options }: InputProps) => {
  return (
    <FormControl className={styles.root}>
      {!value && <InputLabel id="simple-select-label">{label}</InputLabel> }
      <Select
        labelId="simple-select-label"
        id="simple-select"
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        className={clsx(styles.select, className)}
        IconComponent={selectArrow}
      >
        {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
