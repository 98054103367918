import { FC } from 'react';

export const hoc = <
  SourceProps extends object,
  HookProps,
  DefaultProps extends Partial<SourceProps> = object,
>(
  hook: (props: SourceProps) => HookProps,
  Source: FC<HookProps & SourceProps>,
) => {
  const Component = (props: SourceProps) => <Source {...hook(props)} {...props} />;

  return Component;
};
