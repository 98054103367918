import { useState, useEffect } from 'react';

import channels from '../../assets/data/channels.data.json';
import { ChannelInterface } from '../../types';
import { DropdownHookProps, DropdownProps } from './dropdown.props';

export const useDropdownHook = ({
  canEditSource,
  canEditCampaing,
  canEditMedium,
  canEditContent,
  canEditTerm,
  setSource,
  setMedium,
  setContent,
  setCampaing,
  setTerm,
  /* tslint:disable:no-empty */
  clearAllAttributes,
  /* tslint:disable:no-empty */
  setAllAsEdit,
}: DropdownProps): DropdownHookProps => {
  const [valueWithAbs, setValueWithAbs] = useState<string>('');
  const [valueSmm, setValueSmm] = useState<string>('');
  const [valueOther, setValueOther] = useState<string>('');

  const updateChannel = (channel: ChannelInterface) => {
    setAllAsEdit();
    clearAllAttributes();

    if (!channel) {
      return;
    }

    if (channel['utm_source']) {
      canEditSource(false);
      setSource(channel['utm_source']);
    }

    if (channel['utm_medium']) {
      canEditMedium(false);
      setMedium(channel['utm_medium']);
    }

    if (channel['utm_campaign']) {
      canEditCampaing(false);
      setCampaing(channel['utm_campaign']);
    }

    if (channel['utm_content']) {
      canEditContent(false);
      setContent(channel['utm_content']);
    }

    if (channel['utm_term']) {
      canEditTerm(false);
      setTerm(channel['utm_term']);
    }
  };

  useEffect(() => {
    if (valueWithAbs) {
      const channel = channels.find((item) => item.value === valueWithAbs) as ChannelInterface;

      updateChannel(channel);
      setValueSmm('');
      setValueOther('');
    }
  }, [valueWithAbs]);

  useEffect(() => {
    if (valueOther) {
      const channel = channels.find((item) => item.value === valueSmm) as ChannelInterface;
      updateChannel(channel);
      setValueWithAbs('');
      setValueSmm('');
    }
  }, [valueOther]);

  useEffect(() => {
    if (valueSmm) {
      const channel = channels.find((item) => item.value === valueSmm) as ChannelInterface;
      updateChannel(channel);
      setValueWithAbs('');
      setValueOther('');
    }
  }, [valueSmm]);

  return { valueWithAbs, valueSmm, valueOther, setValueWithAbs, setValueSmm, setValueOther };
};
