import Api from '../../api';
import { routes } from '../../routes';
import { TUser } from 'react-telegram-auth';
import { LoginTelegramResponseDto } from './dto/login-telegram-response.dto';

export default class AuthService {
  static async login(user: TUser): Promise<LoginTelegramResponseDto> {
    try {
      return Api.post<TUser, LoginTelegramResponseDto>(routes.AUTH_TELEGRAM, user, true);
    } catch (error) {
      throw new Error('Error save data. Please refresh the page.');
    }
  }
}
