import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import BasicSelect from './components/select';
import SelectedListItem from './components/list';
import ContentService from '../../../api/services/content/content.service';
import IContent from '../../../api/services/content/interfaces/content.interface';
import { Dropdown } from 'components/dropdown';
import { useSnackbar } from 'notistack';
import { UtmGeneratorCabinetFormComponent } from '../../../components/utm-generator-main-form/utm-generator-cabinet-form.component';
import ProjectService from 'api/services/project/project.service';
import { v4 as uuid } from 'uuid';
import { Loader } from 'components/MainLoader';

const lang = 'UKR'; // TODO

export const LabelDesignerPage = () => {
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IContent | undefined>();
  const [projectData, setProjectData] = useState<any>();
  const [selectedUrl, setSelectedUrl] = useState<string>('');
  const [lastProjectId, setLastProjectId] = useState<string>('');
  const [landingsList, setLandingsList] = useState<any>();

  const fetchProjects = async () => {
    setIsLoading(true);
    const data = await ProjectService.getProjects();
    setLastProjectId(data[data.length - 1].id.toString());
    setIsLoading(false);
  };

  const fetchProject = async () => {
    setIsLoading(true);
    const data = await ProjectService.getProject(lang, lastProjectId);
    setLandingsList(data.landingPages);
    setProjectData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProjects();
    ContentService.getContent(lang)
      .then((content) => {
        setData(content);
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    if (lastProjectId?.length) {
      fetchProject();
    }
  }, [lastProjectId]);

  useEffect(() => {
    if (landingsList) {
      setIsLoading(true);
      const newLandingsList = landingsList.map((el: any) => {
        return { ...el, id: uuid() };
      });
      setLandingsList(newLandingsList);
      setIsLoading(false);
    }
  }, [projectData]);

  return (
    <div className={styles.root}>
      {isLoading && <Loader />}
      <h1 className={styles.title}>Конструктор міток</h1>
      <div className={styles.formWrapper}>
        <UtmGeneratorCabinetFormComponent
          landingsList={landingsList}
          channels={data?.channels}
          url={selectedUrl}
          setSelectedUrl={setSelectedUrl}
        />
      </div>
    </div>
  );
};
