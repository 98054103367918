import React from 'react';
import { Box } from '@mui/material';

import ellipse from '../assets/img/ellipses/ellipse-1.svg';
import Header from './header/Header';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import styles from './MainLayout.module.scss';

interface MainLayoutProps {
  children: JSX.Element;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const location = useLocation();

  return (
    <Box className='home-page-wrapper'>
      {location.pathname === '/' && <div className='first-background' />}
      {location.pathname === '/' && <img src={ellipse} alt='' className='first-ellipse' />}
      <Header />
      <div className={styles.main__content}>{children}</div>
      <Footer />
    </Box>
  );
}
