import styles from './backBtn.module.scss';
import { ReactComponent as BackIcon } from 'assets/img/icons/login_flow_back_icon.svg';

interface backBtnPropsTypes {
  onGoBackStep: () => void;
}

export function BackBtn({ onGoBackStep }: backBtnPropsTypes): JSX.Element {
  return (
    <a onClick={onGoBackStep} className={styles.back}>
      <BackIcon /> Back
    </a>
  );
}
