import cx from 'classnames'
import { useContext, useEffect, useState } from 'react';
import styles from './modals.module.scss'
import translations from '../../../consts/translations'
import { AppConfig } from 'config';
import { LanguagesEnum } from '../../../common/language.type';
import { LanguageContext } from 'layouts/HomeLayout';

type InputProps = {
  openContactUsModal: boolean;
  setOpenContactUsModal: (v: boolean) => void;
}

const ContactUsModal = ({ openContactUsModal, setOpenContactUsModal }: InputProps) => {
  const language: LanguagesEnum = useContext(LanguageContext) as LanguagesEnum;
  const [closeEffect, setCloseEffect] = useState(false)
  useEffect(() => {
    const body = document.body

    if (openContactUsModal) {
      body.classList.add('modal-open')
    }

    if (!openContactUsModal) {
      body.classList.remove('modal-open')
    }
  }, [openContactUsModal])

  const handleClick = (e: any) => {
    if (
      e.target.matches('#contact-us-modal-wrapper')
      || e.target.matches('#close-button')
      || e.target.matches('#close-icon')
    ) {
      setCloseEffect(true)
  
      setTimeout(() => {
        setOpenContactUsModal(false)
        setCloseEffect(false)
      }, 300)
    }
  }
  
  return (
    <div
      className={cx({
        [styles.modalWrapper]: openContactUsModal,
        [styles['modalWrapper--close']]: closeEffect
      })}
      onClick={(e) => handleClick(e)}
      id="contact-us-modal-wrapper"
      style={{ position: 'fixed' }}
    >
      <div
        className={cx({
          [styles.modal]: true,
          [styles['modal--open']]: openContactUsModal,
          [styles['modal--close']]: closeEffect
        })}
        >
        <button
          className={styles.modal__close}
          onClick={(e) => handleClick(e)}
          id="close-button"
        >
          <img id="close-icon" className={styles.modal__closeIcon} src={`${AppConfig.IMAGE_URL}/blog/img/close_modal_icon.svg`} alt="close icon" />
        </button>

        <section className={styles.modal__section}>
          <h2 className={styles.modal__heading}>{translations[language].contacts.phone}</h2>
          <div className={styles.modal__phones}>
            <div className={styles.modal__phone}>
              <img src={`${AppConfig.IMAGE_URL}/blog/img/contacts/phone_orange_icon.svg`} alt="phone icon" />
              <a className={styles.modal__tel} href="tel:0800 333 235">0800 333 235</a>
            </div>
          </div>
          <div className={styles.modal__line} />
        </section>

        <section className={styles.modal__section}>
          <h2 className={styles.modal__heading}>{translations[language].contacts.messangers}</h2>
          <div className={styles.modal__contacts}>
            <button className={styles.modal__contact}>
              <img className={styles.modal__img} src={`${AppConfig.IMAGE_URL}/blog/img/contacts/whatsapp_icon.svg`} alt="WhatsApp contact" />
            </button>
            <button className={styles.modal__contact}>
              <img className={styles.modal__img} src={`${AppConfig.IMAGE_URL}/blog/img/contacts/telegram_icon.svg`} alt="Telegram contact" />
            </button>
            <button className={styles.modal__contact}>
              <img className={styles.modal__img} src={`${AppConfig.IMAGE_URL}/blog/img/contacts/facebookm_icon.svg`} alt="Facebook contact" />
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ContactUsModal
