import React from 'react';

import './links-block.scss';
import instagram from '../../assets/img/instagram.svg';
import facebook from '../../assets/img/facebook.svg';
import twitter from '../../assets/img/twitter.svg';
import ellipseThird from '../../assets/img/ellipses/ellipse-3.svg';
import ellipseFourth from '../../assets/img/ellipses/ellipse-4.svg';
import { windowSize } from '../../hoc';

type InputProps = {
  title: string;
  text: string;
};

export const LinksBlock = windowSize<InputProps>(({ width, title, text }) => {
  return (
    <div className="links-wrapper">
      <img src={width >= 500 ? ellipseThird : ellipseFourth} className="links-ellipse"/>
      <div className="links-title">{title}</div>
      <div className="links-subtitle">{text}</div>
      <div className="links">
        <a href="https://developer.mozilla.org">
          <img src={twitter} className="links-icon" alt="twitter"/>
        </a>
        <a href="https://developer.mozilla.org">
          <img src={facebook} className="links-icon links-icon-facebook" alt="facebook"/>
        </a>
        <a href="https://developer.mozilla.org">
          <img src={instagram} className="links-icon" alt="instagram"/>
        </a>
      </div>
    </div>
  );
});
