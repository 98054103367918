import classes from './styles.module.scss';
import { TLoginButton, TLoginButtonSize, TUser } from 'react-telegram-auth';

type InputProps = {
  onAuthCallback: (user: TUser) => void;
};

const LoginForm = ({ onAuthCallback }: InputProps) => {
  return (
    <div className={classes.root}>
      <TLoginButton
        botName='ProfitStoreTestBot'
        buttonSize={TLoginButtonSize.Large}
        lang='en'
        usePic={false}
        cornerRadius={20}
        onAuthCallback={onAuthCallback}
        requestAccess={'write'}
      />
    </div>
  );
};

export default LoginForm;
