import { useEffect } from 'react';

function useNoScrollBody(isModalOpen) {
  useEffect(() => {
    const { body } = document;
    if (isModalOpen) {
      body.classList.add('noscroll');
    } else {
      body.classList.remove('noscroll');
    }
  }, [isModalOpen]);

  return null;
}

export default useNoScrollBody;
