import styles from './styles.module.scss';

type InputProps = {
  title: string;
}

export const TalkBubbleShapeV1 = ({ title }: InputProps) => {
  return (<div className={styles.root}>
    {title}
  </div>);
};
