import { LanguagesEnum } from '../common/language.type';

export const detectLanguage = (path: string): LanguagesEnum => {
  let language: LanguagesEnum = LanguagesEnum.UK;
  if (path.includes('uk')) {
    language = LanguagesEnum.UK;
  } else {
    language = LanguagesEnum.EN;
  }
  return language;
};

export const buildPathByLanguage = (inputPath: string, language: LanguagesEnum): string => {
  let path = inputPath.replace(/\/en$/, '/');
  if (language === LanguagesEnum.UK) {
    path += '/uk';
  }
  return path.replace('//', '/');
};
