import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import { HomePage, LoginPage, NotFoundPage } from '../pages';
import { Pages } from './routers';
import CabinetLayout from '../components/CabinetLayout';
import { LabelDesignerPage } from '../pages/cabinet/label-designer';
import { CreativesPage } from '../pages/cabinet/creatives';
import { AdvertisingChannelsPage } from '../pages/cabinet/advertising-channels';
import { ProductsPage } from '../pages/cabinet/products';
import { ProjectsPage } from '../pages/cabinet/projects';
import { SettingsPage } from '../pages/cabinet/settings';
import { RequireAuth } from '../guards/require-auth.guard';
import { HomeLayout } from 'layouts/HomeLayout';

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path={Pages.cabinet}
        element={
          <RequireAuth>
            <CabinetLayout>
              <Outlet />
            </CabinetLayout>
          </RequireAuth>
        }
      >
        <Route path={Pages.cabinet} element={<LabelDesignerPage />} />
        <Route path={Pages.cabinetAdvertisingChannels} element={<AdvertisingChannelsPage />} />
        <Route path={Pages.cabinetProducts} element={<ProductsPage />} />
        <Route path={Pages.cabinetCreatives} element={<CreativesPage />} />
        <Route path={Pages.cabinetProjects} element={<ProjectsPage />} />
        <Route path={Pages.cabinetSettings} element={<SettingsPage />} />
      </Route>
      <Route
        path={Pages.login}
        element={
          <HomeLayout>
            <LoginPage />
          </HomeLayout>
        }
      />
      <Route
        path={`${Pages.login}/uk`}
        element={
          <HomeLayout>
            <LoginPage />
          </HomeLayout>
        }
      />
      <Route
        path={Pages.home}
        element={
          <HomeLayout>
            <HomePage />
          </HomeLayout>
        }
      />
      <Route
        path={`${Pages.home}/uk`}
        element={
          <HomeLayout>
            <HomePage />
          </HomeLayout>
        }
      />
      <Route
        path={Pages.base}
        element={
          <HomeLayout>
            <HomePage />
          </HomeLayout>
        }
      />
      <Route
        path={`${Pages.base}/uk`}
        element={
          <HomeLayout>
            <HomePage />
          </HomeLayout>
        }
      />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export { AppRouter };
