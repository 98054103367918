/* eslint-disable react/no-unescaped-entities*/
import React from 'react';
import { Grid } from '@mui/material';

import './info-block.scss';
import ellipse from '../../assets/img/ellipses/ellipse-2.svg';

type InputProps = {
  title: string;
  text: string;
  options: string[];
};

export const InfoBlock = ({ title, text, options }: InputProps) => {
  return (
    <Grid container className="info-block">
      <img src={ellipse} className="second-ellipse"/>
      <Grid item md={5} xs={12} className="info-block-left">
        <p className="info-block-title">{title}</p>
        <p className="left-block-content">
          {text}
        </p>
      </Grid>
      <Grid item md={5} xs={12} className="info-block-right">
        <ul className="info-block-list">
          {options.map((o, index) => (<li key={index}>{o}</li>))}
        </ul>
      </Grid>
    </Grid>
  );
};
