import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';

import './index.scss';
import { App } from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
    <App/>
  </SnackbarProvider>
);
