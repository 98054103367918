import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import IContent from 'api/services/content/interfaces/content.interface';
import ContentService from 'api/services/content/content.service';
import { useSnackbar } from 'notistack';
import { hoc } from 'hoc';
import {
  IUtmGeneratorHook,
  useUtmGeneratorHook,
} from 'components/utm-generator-main-form/use-utm-generator.hook';
import { Dropdown } from 'components';
import ProjectService from 'api/services/project/project.service';
import { SelectChannelBtn } from './components/selectChannelBtn/selectChannelBtn';
import { Loader } from 'components/MainLoader';
import cn from 'classnames';
import { Responsibles } from './components/responsibles/responsibles';
import { Utm } from './components/utm/utm';
import { Params } from './components/params/params';
import channels from 'assets/data/channels.data.json';

const lang = 'UKR'; // TODO

export const AdvertisingChannelsPage = hoc<any, IUtmGeneratorHook>(
  useUtmGeneratorHook,
  ({
    utmAttributes,
    setUtmAttributes,
    editAttributes,
    link,
    setSource,
    setMedium,
    setContent,
    setCampaing,
    setTerm,
    setProtocol,
    setBaseUrl,
    canEditSource,
    canEditCampaing,
    canEditMedium,
    canEditContent,
    canEditTerm,
    clearAllAttributes,
    setAllAsEdit,
  }) => {
    const snackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<IContent | undefined>();
    const [projectData, setProjectData] = useState<any>();
    const [lastProjectId, setLastProjectId] = useState<string>('');
    const [activeChannelBtnLabel, setActiveChannelBtnLabel] = useState<string>('');
    const [activeSwitchItem, setActiveSwitchItem] = useState<'Відповідальні' | 'UTM' | 'Параметри'>(
      'Відповідальні',
    );

    const fetchProjects = async () => {
      setIsLoading(true);
      const data = await ProjectService.getProjects();
      setLastProjectId(data[data.length - 1].id.toString());
      setIsLoading(false);
    };

    const fetchProject = async () => {
      setIsLoading(true);
      const data = await ProjectService.getProject(lang, lastProjectId);
      setProjectData(data);
      setActiveChannelBtnLabel(data.channels[0].title);
      setIsLoading(false);
    };

    const fetchContent = () => {
      setIsLoading(true);
      ContentService.getContent(lang)
        .then((content) => {
          setData(content);
        })
        .catch((error) => {
          snackbar.enqueueSnackbar(error.message, { variant: 'error' });
          setIsLoading(false);
        });
      setIsLoading(false);
    };

    useEffect(() => {
      fetchProjects();
      fetchContent();
    }, []);

    useEffect(() => {
      if (lastProjectId?.length) {
        fetchProject();
      }
    }, [lastProjectId]);

    const switchUtmInputsValue = (
      campaingValue: string,
      contentValue: string,
      termValue: string,
      canEdit: boolean,
    ) => {
      setCampaing(campaingValue);
      setContent(contentValue);
      setTerm(termValue);
      canEditCampaing(false);
      canEditContent(false);
      canEditTerm(false);
    };

    useEffect(() => {
      if (activeChannelBtnLabel.length) {
        if (activeChannelBtnLabel == 'Facebook') {
          const filterChannel = channels.filter((el) => el.value == 'Facebook');
          switchUtmInputsValue(
            filterChannel[0].utm_campaign,
            filterChannel[0].utm_content,
            filterChannel[0].utm_term,
            false,
          );
        } else if (activeChannelBtnLabel == 'Tik-Tok') {
          const filterChannel = channels.filter((el) => el.value == 'Tik-Tok');
          switchUtmInputsValue(
            filterChannel[0].utm_campaign,
            filterChannel[0].utm_content,
            filterChannel[0].utm_term,
            false,
          );
        } else if (activeChannelBtnLabel == 'Google') {
          const filterChannel = channels.filter((el) => el.value == 'Google');
          switchUtmInputsValue(
            filterChannel[0].utm_campaign,
            filterChannel[0].utm_content,
            filterChannel[0].utm_term,
            false,
          );
        } else {
          switchUtmInputsValue('', '', '', true);
        }
      }
    }, [activeChannelBtnLabel]);

    return (
      <div className={styles.channels}>
        {isLoading && <Loader />}
        <h1 className={styles.channels__title}>Рекламні канали</h1>
        <div className={styles.channels__body}>
          <div className={styles.channels__selector}>
            <Dropdown
              isCabinet
              channels={data?.channels || []}
              canEditSource={canEditSource}
              canEditCampaing={canEditCampaing}
              canEditMedium={canEditMedium}
              canEditContent={canEditContent}
              canEditTerm={canEditTerm}
              setSource={setSource}
              setMedium={setMedium}
              setContent={setContent}
              setCampaing={setCampaing}
              setTerm={setTerm}
              setAllAsEdit={setAllAsEdit}
              clearAllAttributes={clearAllAttributes}
            />
          </div>
          <div className={styles.channels__filters}>
            {projectData?.channels?.map((el: any) => {
              return (
                <SelectChannelBtn
                  activeChannelBtnLabel={activeChannelBtnLabel}
                  btnData={el}
                  key={el.id}
                />
              );
            })}
          </div>
          <div className={`${styles.channels__switch} ${styles.switch}`}>
            <a
              onClick={() => setActiveSwitchItem('Відповідальні')}
              className={cn(styles.switch__item, {
                [styles.active]: activeSwitchItem == 'Відповідальні',
              })}
            >
              Відповідальні
            </a>
            <a
              onClick={() => setActiveSwitchItem('UTM')}
              className={cn(`${styles.switch__item}  ${styles.utm}`, {
                [styles.active]: activeSwitchItem == 'UTM',
              })}
            >
              UTM
            </a>
            <a
              onClick={() => setActiveSwitchItem('Параметри')}
              className={cn(`${styles.switch__item} ${styles.params}`, {
                [styles.active]: activeSwitchItem == 'Параметри',
              })}
            >
              Параметри
            </a>
          </div>
          <div className={styles.channels__content}>
            {activeSwitchItem === 'Відповідальні' && (
              <Responsibles responsibleData={projectData?.channels[0]?.responsiblePersons} />
            )}
            {activeSwitchItem === 'UTM' && (
              <Utm
                utmAttributes={utmAttributes}
                editAttributes={editAttributes}
                setSource={setSource}
                setMedium={setMedium}
                setContent={setContent}
                setCampaing={setCampaing}
                setTerm={setTerm}
                canEditSource={canEditSource}
                canEditCampaing={canEditCampaing}
                canEditMedium={canEditMedium}
                canEditContent={canEditContent}
                canEditTerm={canEditTerm}
              />
            )}
            {activeSwitchItem === 'Параметри' && <Params project={projectData} />}
          </div>
        </div>
      </div>
    );
  },
);
