import { IResponsiblePerson } from './responsible-person';
import { IAdsManagementSystem } from './ads-management-system';
import { IChannelAdditionalParameter } from './channel-additional-parameter';

export enum ChannelType {
  ADS_CABINET = 'adsCabinetChannels',
  SMM = 'smmChannels',
  OTHER = 'otherChannels',
}

export interface IChannel {
  id: number;
  title: string;
  icon?: string;
  responsiblePersons: IResponsiblePerson[];
  additionalParameters: IChannelAdditionalParameter;
  adsManagementSystems: IAdsManagementSystem[];
  type: ChannelType;
}
