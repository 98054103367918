import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import SkipButton from 'components/buttons/skip-button';
import SubmitButton from 'components/buttons/main-button/submit';

import styles from './styles.module.scss';
import { AddButton } from '../../../../../components/buttons/add-button';
import { InputTable } from '../../../../../components/inputs/input-table';
import { SelectTable } from '../../../../../components/selects/select-table';
import { IStepProps } from '../istep.props';
import ConfirmModal from '../../confirm-modal';
import { FieldArray, Form, Formik } from 'formik';
import { BackBtn } from '../../backBtn/backBtn';

const emptySite = {
  protocol: 'http',
  url: '',
  productName: '',
};

const protocols = [
  {
    label: 'http',
    value: 'http',
  },
  {
    label: 'https',
    value: 'https',
  },
];

export const FillLandingProduct = ({
  onStepFinish,
  onGoBackStep,
  onGoToNextStep,
  onSkip,
}: IStepProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.root}>
      <div className={styles.root__container}>
        <BackBtn onGoBackStep={onGoBackStep} />
        <h2>Step 3/5. Fill in landing pages and product names</h2>

        <div className={styles.content}>
          <Formik
            initialValues={{
              sites: [emptySite],
            }}
            onSubmit={(values) => {
              onStepFinish(values.sites.filter((site) => site.url && site.productName));
              setOpen(true);
            }}
            render={({ values }) => (
              <Form>
                <FieldArray
                  name='sites'
                  render={(arrayHelpers) => (
                    <div className={styles.container}>
                      <Table className={styles.table}>
                        <TableHead className={styles.header}>
                          <TableRow>
                            <TableCell>http/https</TableCell>
                            <TableCell>Landing page</TableCell>
                            <TableCell>Product name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={styles.body}>
                          {values.sites.map((site, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <SelectTable
                                  value={values.sites[index].protocol}
                                  name={`sites.${index}.protocol`}
                                  onChange={arrayHelpers.form.handleChange}
                                  options={protocols}
                                />
                              </TableCell>
                              <TableCell>
                                <InputTable
                                  id={`product-tittle-${index}`}
                                  name={`sites.${index}.url`}
                                  placeholder={`site.com/product_tittle_${index + 1}`}
                                  onChange={arrayHelpers.form.handleChange}
                                />
                              </TableCell>
                              <TableCell>
                                <InputTable
                                  id={`product-${index}`}
                                  name={`sites.${index}.productName`}
                                  placeholder={`product_${index + 1}`}
                                  onChange={arrayHelpers.form.handleChange}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <div className={styles.add}>
                        <AddButton
                          onClick={() => {
                            arrayHelpers.push(emptySite);
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
                <div className={styles.actions}>
                  <SkipButton onClick={onSkip} />
                  <SubmitButton>Ready</SubmitButton>
                </div>
              </Form>
            )}
          />
        </div>

        <ConfirmModal
          open={open}
          onClose={() => setOpen(false)}
          onNext={() => {
            setOpen(false);
            onGoToNextStep();
          }}
          title='Fill in and manage your ads'
          text='This will help you organize your work better.'
        />
      </div>
    </div>
  );
};
