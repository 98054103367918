import { Pages } from 'containers/routers';
import { Navigate, useLocation } from 'react-router-dom';

type InputProps = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: InputProps) => {
  // const { authed } = useAuth();
  const authed = true;
  const location = useLocation();

  return authed ? (
    children
  ) : (
    <Navigate to={Pages.login} replace state={{ path: location.pathname }} />
  );
};
