const dropdown = {
  products: [
    {
      uk: {
        title: 'Аналітика для бізнесу',
        li: [
          { title: 'Маркетингова аналітика', link: '#' },
          { title: 'Фінансова аналітика', link: '#' }
        ]
      },
      en: {
        title: 'Analytics from bussiness',
        li: [
          { title: 'Marketing analytic', link: '#' },
          { title: 'Finance analytic', link: '#' }
        ]
      }
    },
    {
      uk: {
        title: 'Інструменти',
        li: [
          { title: 'UTM-builder', link: '#' },
          { title: 'Short-link builder', link: '#' },
          { title: 'Календар', link: '#' }
        ]
      },
      en: {
        title: 'Instruments',
        li: [
          { title: 'UTM-builder', link: '#' },
          { title: 'Short-link builder', link: '#' },
          { title: 'Calendar', link: '#' }
        ]
      }
    }
  ],
  projects: [
    {
      uk: {
        title: 'Ідеальна аналітика',
        li: [{ title: 'Онлайн-освіта', link: 'https://profit.store/idealna-analityka-dlia-online-shkil' }]
      },
      en: {
        title: 'Best analytic',
        li: [{ title: 'Study-online', link: 'https://profit.store/best-analytics-for-education' }]
      }
    }
  ],
  categories: [
    {
      uk: {
        title: 'Категорії',
        li: [
          { title: 'Експертно', link: '/expert' },
          // eslint-disable-next-line quotes
          { title: "Інтерв'ю", link: '/interview' },
          { title: 'Дослідження', link: '/research' },
          { title: 'Про бізнес', link: '/about-business' },
          { title: 'Ідеальна аналітика', link: '/perfect-analytics' },
          { title: 'Сервіси', link: '/services' },
        ]
      },
      en: {
        title: 'Categories',
        li: [
          { title: 'Expert', link: '/expert' },
          { title: 'Interview', link: '/interview' },
          { title: 'Research', link: '/research' },
          { title: 'About business', link: '/about-business' },
          { title: 'Perfect analytics', link: '/perfect-analytics' },
          { title: 'Services', link: '/services' },
        ]
      }
    }
  ]
}

export default dropdown
